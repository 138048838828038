import {UniteSessionDiscipline} from './UniteSessionDiscipline';
import {MoyenneUnite} from './MoyenneUnite';
import {UniteSessionDisciplineMatiere} from './UniteSessionDisciplineMatiere';
import {MoyenneDiscipline} from './MoyenneDiscipline';

export class NoteMatiere {
    id: string;
    note: number;
    moyenneDiscipline: MoyenneDiscipline;
    uniteSessionDisciplineMatiere: UniteSessionDisciplineMatiere;

}
