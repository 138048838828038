import {Cours, CoursRequest} from './cours';

export class Theme {
    id: string;
    titre: string;
    cours: Cours[];
}

export class ThemeRequest {
    id: string;
    titre: string;
    cours: CoursRequest[];
}
