import {Theme} from './theme';
import {Discipline} from './discipline';
import {Enseignant} from './enseignant';
import {Niveau} from './niveau';

export class Matiere {
    id: string;
    nom: string;
    themes: Theme[];
    created_Date: string;
    niveau: Niveau;
    discipline: Discipline;


    static toMatiereRequest(matiere: Matiere): Matiere {
        delete matiere.niveau.label;
        delete matiere.discipline;

        if (matiere.themes) {
            matiere.themes.forEach(function (elt) {
                if (elt.cours) {

                    elt.cours.forEach(
                        function (elt2: any) {
                            elt2.initializedObjectives = false;
                            if (elt2.enseignant != null) {
                                delete elt2.enseignant.cin;
                                delete elt2.enseignant.nom;
                                delete elt2.enseignant.prenom;
                                delete elt2.coursType.label;
                            } else {
                                elt2.enseignant = new Enseignant();
                            }
                            // elt2.coursObjectif = elt2.coursObjectif.map(obj => obj.id);
                        }
                    );
                }
            });
        }
        return matiere;
    }
}


