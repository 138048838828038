import {Niveau} from './niveau';
import {MoyenneSession} from './MoyenneSession';
import {UniteSession} from './UniteSession';

export class Session {
    id: string;
    nom: string;
    annee: string;
    semestre: string;
    date_publication: Date;
    niveau:Niveau;
    moyennesSessions: MoyenneSession[];
    unitesSessions: UniteSession[];
}
