import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrudExampleRoutingModule } from './crud-example-routing.module';
import { ListExampleComponent } from './list-example/list-example.component';
import { AddExampleComponent } from './add-example/add-example.component';

@NgModule({
  declarations: [ListExampleComponent, AddExampleComponent],
  imports: [
    CommonModule,
    CrudExampleRoutingModule
  ]
})
export class CrudExampleModule { }
