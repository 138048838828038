import {Discipline} from './discipline';
import {Matiere} from './matiere';
import {NoteMatiere} from './NoteMatiere';

export class UniteSessionDisciplineMatiere {
    id: string;
    ponderation: number;
    matiere: Matiere;
    notesMatieres: NoteMatiere[];

}
