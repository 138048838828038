import { Component, OnInit } from '@angular/core';
import {UniteService} from '../../shared/services/unite.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatiereService} from '../../shared/services/matiere.service';
import {SessionPlanningService} from '../../shared/services/sessionPlanning.service';
import {PeriodeSeanceService} from '../../shared/services/periodeSeance.service';
import {ConfigPlanningService} from '../../shared/services/configPlanning.service';
import {ConfigPlanning} from '../../shared/models/planning/ConfigPlanning';
import {Utils} from '../../shared/utils/utils';
declare let swal : any;
@Component({
  selector: 'app-list-config-planning',
  templateUrl: './list-config-planning.component.html',
  styleUrls: ['./list-config-planning.component.css']
})
export class ListConfigPlanningComponent implements OnInit {

  configs_plannings : ConfigPlanning[];

  constructor(private uniteService: UniteService,
              private router: Router,
              private route: ActivatedRoute,
              private matiereService: MatiereService,
              private sessionPlanningService: SessionPlanningService,
              private periodesSeancesService: PeriodeSeanceService,
              private configPlanningService: ConfigPlanningService) { }

  ngOnInit() {
    this.getAllConfigsPlannings();
  }

  getAllConfigsPlannings() {
    let that = this;
    Utils.enableLoader();

    this.configPlanningService.getAllConfigsPlannings().subscribe(
        (data: any) => {
          this.configs_plannings = data;
          Utils.initializeDataTables('datatable',100,5);
          Utils.disableLoader();
        }, (error) => {
          Utils.showErrorNoty('Erreur');
          Utils.disableLoader();
        }
    );
  }

  onDeleteConfig(i: number) {
    let that  = this;
    swal({
          title: "Êtes vous sûrs?",
          text: "Vous ne pouvez plus reverser la suppression!",
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Oui, Supprimer !",
          closeOnConfirm: true
        },
        function(){
          Utils.enableLoader();

          that.configPlanningService.deleteConfigPlanning(that.configs_plannings[i].id).subscribe(
              () => {
                that.configs_plannings=that.configs_plannings.filter(conf=>conf.id!=that.configs_plannings[i].id);
                Utils.initializeDataTables('datatable', 100, 5);
                Utils.disableLoader();
                Utils.showSuccessNoty("La configuration a été supprimée");
              },
              () => {
                Utils.disableLoader();
                Utils.showErrorNoty("La configuration n'a pas été supprimée");
              }
          );
        });
  }
}
