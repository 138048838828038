import {Component, OnInit} from '@angular/core';
import {Matiere} from '../../shared/models/matiere';
import {Theme} from '../../shared/models/theme';
import {Cours, CoursObjectif, CoursType} from '../../shared/models/cours';
import {MatiereService} from '../../shared/services/matiere.service';
import {EnseignantService} from '../../shared/services/enseignant.service';
import {Enseignant} from '../../shared/models/enseignant';
import {ActivatedRoute, Router} from '@angular/router';
import Noty from 'noty';
import {Utils} from '../../shared/utils/utils';
import {Niveau} from '../../shared/models/niveau';

declare var printJS: any;
declare let jQuery: any;


@Component({
    selector: 'app-add-matiere',
    templateUrl: './add-matiere.component.html',
    styleUrls: ['./add-matiere.component.css']
})

export class AddMatiereComponent implements OnInit {

    constructor(private matiereService: MatiereService,
                private enseignantService: EnseignantService,
                private router: Router,
                private route: ActivatedRoute,
    ) {
        this.isEditMode = router.url.indexOf('edit') > 0;
        this.matiere_id = this.route.snapshot.paramMap.get('matiereId');

    }

    enseignants: Enseignant[];
    selected: number;
    isEditMode: boolean;
    matiere_id;
    matiere = new Matiere();
    niveaux: Niveau[];
    coursTypes: CoursType[];
    objectifs: CoursObjectif[] = [];
    objectifsSelected: any;
    objectifToAdd: CoursObjectif;
    isSubmitted = false;
    id_obj_temp = 0;

    ngOnInit() {
        this.objectifToAdd = new CoursObjectif();
        this.objectifToAdd.titre = '';
        this.matiere.niveau = new Niveau();
        this.getAllCoursObjectifs();
        this.getAllEnseignant();
        this.getAllNiveaux();
        this.getAllCoursType();
        if (this.isEditMode) {
            this.getMatiereById(this.matiere_id);
        }
        jQuery('#nom-matiere').focus();
    }


    addTheme() {
        if (!this.matiere.themes) {
            this.matiere.themes = [];
        }

        if (!this.canAddTheme()) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Erreur : Vérifier que tous les champs sont remplis pour les thèmes précédents avant d\'en ajouter un.'
            }).show();
            return;
        }
        for (let theme in this.matiere.themes) {
            jQuery('#accordion-controls-group' + theme + '1').collapse('hide');
        }
        this.matiere.themes.push(new Theme());
        const that = this;
        setTimeout(function () {
            jQuery('#titre-theme-' + (that.matiere.themes.length - 1)).focus();
        }, 20);
        this.addCours(this.matiere.themes.length - 1, 0);
    }

    addCours(i, j) {
        if (!this.matiere.themes[i].cours) {
            this.matiere.themes[i].cours = [];
        }
        const cours = new Cours();
        cours.enseignant = new Enseignant();
        cours.coursType = new CoursType();
        cours.coursObjectif = [];
        if (this.matiere.themes[i].cours.length == 0) {
            this.matiere.themes[i].cours.push(cours);
            this.initSelectObject(i, 0);
        } else {
            this.matiere.themes[i].cours.splice(j + 1, 0, cours);
            this.initSelectObject(i, j + 1);
            setTimeout(function () {
                jQuery('#titre-cours-' + i + '-' + (j + 1)).focus();
            }, 20);
        }
        //console.log('#titre-theme-' + (this.matiere.themes.length - 1));
    }

    removeTheme(i) {
        this.matiere.themes.splice(i, 1);
    }

    removeCours(i, j) {
        this.matiere.themes[i].cours.splice(j, 1);
    }

    onSaveClick() {
        Utils.enableLoader();
        this.isSubmitted = true;
        if (this.matiere.themes) {
            this.matiere.themes.forEach(function (theme) {
                if (theme.cours) {
                    theme.cours.forEach(function (cours) {
                        cours.coursObjectif = cours.coursObjectif.map(function (item: any) {
                            const obj = new CoursObjectif();
                            obj.id = item.id;
                            obj.titre = item.titre;
                            return obj;
                        });
                    });
                }
            });
        }

        if (!this.canSubmit()) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Erreur : Vérifier que tous les champs sont remplis !'
            }).show();
            Utils.disableLoader();

            return;
        }
        this.matiereService.addMatiere(this.matiere).subscribe(
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'success',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'La matière a été bien enregistrée !'
                }).show();
                this.router.navigate(['/matiere/list']);
                Utils.disableLoader();
            },
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Erreur : La matière n\'a pas été enregistrée !'
                }).show();
                Utils.disableLoader();
            }
        );
        setTimeout(function () {
            Utils.disableLoader();
        }, 5000);
    }

    onSaveCoursObjectifClick() {
        if (this.objectifToAdd.titre.trim() == '') {
            return;
        }
        this.enableLoader();
        this.matiereService.addCoursObjectif(this.objectifToAdd).subscribe(
            (data: CoursObjectif) => {
                this.objectifToAdd.titre = '';
                this.objectifs.push(data);
                new Noty({
                    theme: 'metroui',
                    type: 'success',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Un objectif a été enregistré !'
                }).show();
                this.disableLoader();

            },
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Erreur : L\'objectif n\'a pas été enregistré !'
                }).show();
                this.disableLoader();
            }
        );
    }

    getAllEnseignant() {
        this.enableLoader();
        this.enseignantService.getAll().subscribe(
            (data: any) => {
                this.enseignants = data;
            }
        );
    }

    getAllCoursType() {
        this.enableLoader();

        this.matiereService.getAllCoursType().subscribe(
            (data: any) => {
                this.coursTypes = data;
            }
        );
    }

    getAllCoursObjectifs() {

        this.matiereService.getAllCoursObjectifs().subscribe(
            (data: any) => {
                const that = this;
                this.objectifs = data;
            }
        );
    }

    getAllNiveaux() {
        this.enableLoader();

        this.matiereService.getAllNiveaux().subscribe(
            (data: any) => {
                this.niveaux = data;
                this.disableLoader();
            }
        );

    }

    onResetClick() {
        delete this.matiere.niveau.id;
        this.matiere = new Matiere();
        this.matiere.niveau = new Niveau();
    }

    getMatiereById(id: string) {
        this.enableLoader();

        this.matiereService.getMatiereById(id).subscribe(
            (data) => {
                this.matiere = data;
                this.matiere = Matiere.toMatiereRequest(this.matiere);
                this.initAllSelectsObjects();

                this.disableLoader();
            }
        );
    }

    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }

    canAddTheme(): boolean {
        //console.log(this.matiere.themes);
        if (this.matiere.themes) {
            if (this.matiere.themes.some(theme => !theme.titre ||
                theme.titre == '' || (theme.cours && theme.cours.length != 0 &&
                    theme.cours.some(cours => !cours.titre ||
                        cours.titre == '' || !cours.coursType.id || cours.coursObjectif.length == 0)))) {
                // cours.titre == '' || !cours.coursType.id || cours.coursObjectif.length == 0))) {
                return false;
            }
        }
        return true;
    }

    canSubmit(): boolean {
        return !(!this.matiere.nom || this.matiere.nom == '' || !this.matiere.niveau.id || !this.canAddTheme());
    }

    initSelectObject(i, j) {
        const that = this;
        setTimeout(function () {
            const s = jQuery('#selectObjectifs-' + i + '-' + j);
            s.select2({
                tags: true,
                placeholder: 'Objectifs',
                createTag: function (params) {

                    return {
                        id: Math.floor(Math.random() * 10000) + 1,
                        text: params.term,
                        isNew: true,
                    };
                },
            }).on('select2:select', function (e) {
                const objectif = new CoursObjectif();
                if (e.params.data.isNew) {
                    objectif.titre = e.params.data.text;
                    that.id_obj_temp -= 1;
                    objectif.id = '' + that.id_obj_temp;
                    that.objectifs.push(objectif);
                    const elss = s.select2('data');
                    const els = elss.filter(option => option.id != e.params.data.id);
                    els.push(objectif);
                    setTimeout(function () {
                        s.val(els.map(el => el.id)).trigger('change');
                    }, 10);
                } else {
                    objectif.titre = e.params.data.text;
                    objectif.id = e.params.data.id;
                }
                that.matiere.themes[i].cours[j].coursObjectif.push(objectif);

                // //console.log(that.matiere.themes[i].cours[j].coursObjectif);
            }).on('select2:unselecting', function (e) {
                that.matiere.themes[i].cours[j].coursObjectif = that.matiere.themes[i].cours[j].coursObjectif.filter(
                    a => a.id != e.params.args.data.id);
            });
            jQuery('#row-theme-' + i + '-cours-' + j).fadeIn('fast');
        }, 20);
    }

    showSelect(i: number, j: number) {
        let that = this;
        jQuery('#label-' + i + '-' + j).hide();
        if (that.isEditMode && that.matiere.themes[i].cours[j].initializedObjectives == false) {
            jQuery('#selectObjectifs-' + i + '-' + j)
                .val(that.matiere.themes[i].cours[j].coursObjectif.map(el => el.id)).trigger('change');
            that.matiere.themes[i].cours[j].initializedObjectives = true;
        }
        jQuery('#divAboveSelectObjectifs-' + i + '-' + j).fadeIn('fast', function () {
            const s = jQuery('#selectObjectifs-' + i + '-' + j);
            s.focus();

        });
        jQuery('#check-objs-' + i + '-' + j).fadeIn('fast');

    }

    doneObjectifs(i: number, j: number) {

        jQuery('#divAboveSelectObjectifs-' + i + '-' + j).hide();
        jQuery('#label-' + i + '-' + j).fadeIn('fast');
        jQuery('#check-objs-' + i + '-' + j).hide();
    }

    initAllSelectsObjects() {
        let i = 0;
        for (let theme of this.matiere.themes) {
            let j = 0;
            for (let cours in theme.cours) {
                this.initSelectObject(i, j);
                j += 1;
            }
            i += 1;
        }
    }

    exportData() {
        printJS('mainCard', 'html');
    }
}
