import {Component, OnInit} from '@angular/core';
import {UniteService} from '../../shared/services/unite.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigPlanning} from '../../shared/models/planning/ConfigPlanning';
import {Niveau} from '../../shared/models/niveau';
import {SessionPlanning} from '../../shared/models/planning/SessionPlanning';
import {Utils} from '../../shared/utils/utils';
import {ConfigPlanningService} from '../../shared/services/configPlanning.service';
import {MatiereService} from '../../shared/services/matiere.service';
import {SessionPlanningService} from '../../shared/services/sessionPlanning.service';
import {PeriodeSeance} from '../../shared/models/planning/PeriodeSeance';
import {PeriodeSeanceService} from '../../shared/services/periodeSeance.service';
import {ConfigCase} from '../../shared/models/planning/ConfigCase';
import {Unite} from '../../shared/models/unite';

@Component({
    selector: 'app-add-config-planning',
    templateUrl: './add-config-planning.component.html',
    styleUrls: ['./add-config-planning.component.css']
})
export class AddConfigPlanningComponent implements OnInit {

    isEditMode = false;
    config_planning_id: string;

    configPlanning: ConfigPlanning;
    niveaux: Niveau[];
    sessions_plannings: SessionPlanning[];
    periodes_seances: PeriodeSeance[];
    configs_cases: ConfigCase[];
    day_numbers = [1, 2, 3, 4, 5, 6];
    num_periodes: number;
    unites: Unite[];

    constructor(private uniteService: UniteService,
                private router: Router,
                private route: ActivatedRoute,
                private matiereService: MatiereService,
                private sessionPlanningService: SessionPlanningService,
                private periodesSeancesService: PeriodeSeanceService,
                private configPlanningService: ConfigPlanningService) {
        this.isEditMode = router.url.indexOf('edit') > 0;
        this.config_planning_id = this.route.snapshot.paramMap.get('config_planning_id');
    }

    ngOnInit() {
        this.configPlanning = new ConfigPlanning();
        this.configPlanning.niveau = new Niveau();
        this.configPlanning.session_planning = new SessionPlanning();
        this.configPlanning.configs_cases = [];
        this.getAllNiveaux();
        this.getAllSessionsPlannings();
        this.getAllPeriodesSeances();
        this.getAllUnites();

    }

    getConfigPlanningById(config_planning_id: string) {
        Utils.enableLoader();
        this.configPlanningService.getConfigPlanningById(config_planning_id).subscribe(
            (data) => {
                this.configPlanning = data;
                let configs_cases = [];
                for (let i = 1; i < 7; i++) {
                    for (let p of this.periodes_seances) {
                        let c = new ConfigCase;
                        c.num_day = i;
                        c.periode_seance = p;
                        c.unites = [];
                        c.unites_ids = [];
                        configs_cases.push(c);
                    }
                }
                if (this.configPlanning) {
                    for (let config_case of this.configPlanning.configs_cases) {
                        config_case.unites_ids = config_case.unites.map(unite => unite.id);
                        configs_cases[this.num_periodes * (config_case.num_day - 1)
                        + config_case.periode_seance.numero - 1] = config_case;
                    }
                }
                this.configPlanning.configs_cases = configs_cases;
                console.log(this.configPlanning);
                Utils.disableLoader();
            },
            (error) => {
                Utils.showErrorNoty('Configuration non trouvée', 4000);
                Utils.disableLoader();
            }
        );
    }

    getAllNiveaux() {
        Utils.enableLoader();
        this.matiereService.getAllNiveaux().subscribe(
            (data: any) => {
                this.niveaux = data;
                Utils.disableLoader();
            }, (error) => {
                Utils.showErrorNoty('Erreur');
                Utils.disableLoader();
            }
        );

    }

    getAllSessionsPlannings() {
        Utils.enableLoader();
        this.sessionPlanningService.getAllSessionsPlannings().subscribe(
            (data: any) => {
                this.sessions_plannings = data;
                console.log(this.sessions_plannings);
                Utils.disableLoader();
            }, (error) => {
                Utils.showErrorNoty('Erreur');
                Utils.disableLoader();
            }
        );
    }

    onResetClick() {
        this.configPlanning = new ConfigPlanning();
        this.configPlanning.niveau = new Niveau();
        this.configPlanning.session_planning = new SessionPlanning();
        this.configPlanning.configs_cases = [];
        if (!this.isEditMode && this.periodes_seances && this.periodes_seances.length > 0) {
            this.configPlanning.configs_cases = [];
            for (let i = 1; i < 7; i++) {
                for (let p of this.periodes_seances) {
                    let c = new ConfigCase;
                    c.num_day = i;
                    c.periode_seance = p;
                    c.unites = [];
                    c.unites_ids = [];
                    this.configPlanning.configs_cases.push(c);
                    console.log(this.configPlanning);
                }
            }
        }
    }

    saveData() {
        if (!this.canSave()) {
            Utils.showErrorNoty('Remplissez les deux champs!');
            return;
        }

        let tempConf = this.jsonCopy(this.configPlanning);

        tempConf.configs_cases = this.configPlanning.configs_cases.filter(c => {
            return c.unites_ids && c.unites_ids.length > 0;
        });
        if (!tempConf.configs_cases || tempConf.configs_cases.length == 0) {
            Utils.showErrorNoty('Planifiez au moins une unité!');
            return;
        }
        for (let conf of tempConf.configs_cases) {
            conf.unites = conf.unites_ids.map(id => {
                let u = new Unite();
                u.id = id;
                return u;
            });
        }
        let sp = new SessionPlanning();
        sp.id = tempConf.session_planning.id;
        tempConf.session_planning = sp;
        Utils.enableLoader();
        this.configPlanningService.addConfigPlanning(tempConf).subscribe(
            (data: any) => {
                Utils.showSuccessNoty('Configuration fait avec succès !');
                Utils.disableLoader();
                this.router.navigateByUrl('/config-planning/list');
            }, (error) => {
                Utils.showErrorNoty('Erreur');
                this.router.navigateByUrl('/config-planning/list');

                Utils.disableLoader();
            }
        );
    }

    getAllPeriodesSeances() {
        Utils.enableLoader();
        this.periodesSeancesService.getAllPeriodesSeances().subscribe(
            (data: any) => {
                this.periodes_seances = data;
                this.periodes_seances.forEach(ps => {
                    ps.start_hour = new Date(ps.start_hour);
                    ps.end_hour = new Date(ps.end_hour);
                });
                this.num_periodes = this.periodes_seances.length;
                if (!this.isEditMode) {
                    this.configPlanning.configs_cases = [];
                    for (let i = 1; i < 7; i++) {
                        for (let p of this.periodes_seances) {
                            let c = new ConfigCase;
                            c.num_day = i;
                            c.periode_seance = p;
                            c.unites = [];
                            c.unites_ids = [];
                            this.configPlanning.configs_cases.push(c);
                        }
                    }
                } else {
                    this.getConfigPlanningById(this.config_planning_id);
                }
                Utils.disableLoader();
            }, (error) => {
                Utils.showErrorNoty('Erreur');
                this.router.navigateByUrl('/config-planning/list');

                Utils.disableLoader();
            }
        );
    }

    getAllUnites() {
        Utils.enableLoader();
        this.uniteService.getAllUnites().subscribe(
            (data: any) => {
                this.unites = data;
                Utils.disableLoader();
            }, (error) => {
                Utils.showErrorNoty('Erreur');
                this.router.navigateByUrl('/config-planning/list');

                Utils.disableLoader();
            }
        );
    }

    jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    }

    private canSave() {
        return this.configPlanning.niveau && this.configPlanning.niveau.id && this.configPlanning.session_planning && this.configPlanning.session_planning.id;
    }
}
