/**
 * Created by Abbes on 15/06/2017.
 */
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../user.service';

@Injectable()
export class NotAuthorizedUserGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate() {
        if (this.userService.isLoggedIn()) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }

}
