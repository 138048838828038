import {Discipline} from './discipline';
import {UniteSessionDisciplineMatiere} from './UniteSessionDisciplineMatiere';
import {MoyenneDiscipline} from './MoyenneDiscipline';

export class UniteSessionDiscipline {
    id: string;
    ponderation: number;
    discipline: Discipline;
    unitesSessionsDisciplinesMatieres: UniteSessionDisciplineMatiere[];
    moyennesDisciplines: MoyenneDiscipline[];

}
