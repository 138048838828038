import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable()
export class PeriodeSeanceService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }
    getAllPeriodesSeances(){
        const url = Config.baseUrlApi + '/periodesSeances';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));

    }
}
