import {Component, OnInit} from '@angular/core';
import {MatiereService} from '../../shared/services/matiere.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Unite, UniteDiscipline, UniteDisciplineMatiere, UniteType} from '../../shared/models/unite';
import {Matiere} from '../../shared/models/matiere';
import {Discipline} from '../../shared/models/discipline';
import {DisciplineService} from '../../shared/services/discipline.service';
import {Utils} from '../../shared/utils/utils';
import Noty from 'noty';
import {UniteService} from '../../shared/services/unite.service';
import {Niveau} from '../../shared/models/niveau';

declare let jQuery: any;


@Component({
    selector: 'app-add-unite',
    templateUrl: './add-unite.component.html',
    styleUrls: ['./add-unite.component.css']
})
//TODO do not show unavailables disciplines or matieres

export class AddUniteComponent implements OnInit {
    unite = new Unite();
    niveaux: Niveau[];
    uniteTypes: UniteType[];
    disciplines: Discipline[] = [];
    disciplinesToSelect: Discipline[] = [];
    matieres: Matiere[] = [];
    disciplinesToSave = [];
    matieresGrouped = [];
    matieresToSave = [];
    isSubmitted = false;

    matieresPerUnit: Matiere[] = [];

    constructor(private matiereService: MatiereService,
                private router: Router,
                private route: ActivatedRoute,
                private disciplineService: DisciplineService,
                private uniteService: UniteService
    ) {
    }

    ngOnInit() {
        this.unite.niveau = new Niveau();
        this.unite.uniteType = new UniteType();
        this.unite.uniteDiscipline = [];
        this.getAllUniteTypes();
        this.getAllNiveaux();
        this.getAllDisciplines();

        if (this.route.snapshot.paramMap.get('uniteId')) {
            this.getUniteById(this.route.snapshot.paramMap.get('uniteId'));
        }
    }


    getAllNiveaux() {
        this.enableLoader();

        this.matiereService.getAllNiveaux().subscribe(
            (data: any) => {
                this.niveaux = data;
                this.disableLoader();
            }
        );

    }

    getAllUniteTypes() {
        this.enableLoader();
        this.uniteService.getAllUnitesTypes().subscribe(
            (data: any) => {
                this.uniteTypes = data;
                this.disableLoader();
            }
        );
    }


    onSelectNiveau() {
        console.log(this.disciplines);
        this.disciplinesToSelect = this.disciplines.filter(elt => elt.matieres.some(matiere => matiere.niveau.id == this.unite.niveau.id));
    }

    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }

    getAllDisciplines() {
        this.enableLoader();
        this.disciplineService.getAllDisciplines().subscribe(
            (data: any) => {
                this.disciplines = data;
                this.onSelectNiveau();
                console.log(this.unite);
                if (this.unite.id) {
                    this.disciplinesToSave = this.unite.uniteDiscipline.map(x => x.discipline.id);
                }
                console.log(this.disciplinesToSave);
                this.disableLoader();
            }
        );

    }

    onSaveClick() {
        this.isSubmitted = true;
        const baseContext = this;
        const that = this;
        if (!this.canSubmit()) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Erreur : Vérifier que tous les champs sont remplis !'
            }).show();
            return;
        }
        console.log(this.unite);
        this.uniteService.addUnite(this.unite).subscribe(
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'success',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'L\'unité a été bien enregistrée !'
                }).show();
                this.router.navigate(['/unite/list']);
            },
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Erreur : L\'unité n\'a pas été enregistrée !'
                }).show();
            }
        );
    }

    canSelectMatiere(matiere: Matiere) {
        return !(!this.unite.niveau.id || !matiere || !matiere.niveau || !matiere.niveau.id || matiere.niveau.id != this.unite.niveau.id);
    }


    onSelectDiscipline(id) {
        let that = this;
        if (this.disciplines && this.disciplines.length !== 0) {
            this.unite.uniteDiscipline =
                this.disciplines.filter(x => this.disciplinesToSave.findIndex(z => z == x.id) != -1)
                    .map(y => {
                        let pos = that.unite.uniteDiscipline.findIndex(ud => ud.discipline.id == y.id);
                        if (pos == -1) {
                            let u = new UniteDiscipline();
                            u.discipline = y;
                            u.unitesDisciplinesMatieres = [];
                            return u;
                        }
                        else {
                            return that.unite.uniteDiscipline[pos];
                        }
                    });
        }
    }

    canSubmit(): boolean {
        return !(!this.unite.nom || !this.unite.code || !this.unite.niveau.id
            || !this.unite.uniteType || !this.disciplinesToSave || this.disciplinesToSave.length == 0);
    }

    getUniteById(unite_id: string) {
        Utils.enableLoader();
        this.uniteService.getUniteById(unite_id).subscribe((data) => {
            Utils.disableLoader();
            this.unite = data;
            this.matieresToSave = this.unite.uniteDiscipline
                .map(x => x.unitesDisciplinesMatieres.map(y => y.matiere.id));
            if (this.disciplines && this.disciplines.length !== 0) {
                this.disciplinesToSave = this.unite.uniteDiscipline.map(x => x.discipline.id);
            }
        }, (error) => {
            Utils.showErrorNoty('Erreur!');
            Utils.disableLoader();

        });
    }


    onSelectMatiere(ud: UniteDiscipline, $event: any) {
        console.log(ud);
        ud.unitesDisciplinesMatieres = $event.map(x => {
            let pos = ud.unitesDisciplinesMatieres.findIndex(udm => udm.matiere.id == x);
            if (pos == -1) {
                let udm = new UniteDisciplineMatiere();
                let matiere = new Matiere();
                matiere.id = x;
                udm.matiere = matiere;
                return udm;
            }
            else {
                return ud.unitesDisciplinesMatieres[pos];
            }
        });
        console.log(this.unite);
    }
}
