import {Discipline} from './discipline';
import {Matiere} from './matiere';
import {UniteSessionDisciplineMatiere} from './UniteSessionDisciplineMatiere';
import {MoyenneSession} from './MoyenneSession';
import {Niveau} from './niveau';

export class Etudiant {
    id: string;
    nom: string;
    prenom: string;
    cin: string;
    niveau: Niveau;
    moyennesSessions: MoyenneSession[];
}
