import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TokenInterceptor} from './shared/token.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {LayoutsModule} from './layouts/layouts.module';
import {ErrorModule} from './error/error.module';
import {ManageUserModule} from './manage-user/manage-user.module';
import {CanActivateViaAuthGuard} from './shared/services/guards/auth-guard.service';
import {StorageService} from './shared/services/storage.service';
import {UserService} from './shared/services/user.service';
import {AuthService} from './shared/services/auth.service';
import {NotAuthorizedUserGuard} from './shared/services/guards/not-authorized-user-guard';
import {ClientService} from './shared/services/client.service';
import {CanActivateViaAuthSuperAdminAuth} from './shared/services/guards/auth-super-admin-guard';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material';
import {MatProgressButtonsModule} from 'mat-progress-buttons';
import {LoadPageService} from './shared/services/load-page.service';
import {CustomerService} from './shared/services/customer.service';
import {CrudExampleModule} from './crud-example/crud-example.module';
import {FormsModule} from '@angular/forms';
import {MatiereService} from './shared/services/matiere.service';
import {EnseignantService} from './shared/services/enseignant.service';
import {SessionService} from './shared/services/session.service';
import {UniteService} from './shared/services/unite.service';
import {EtudiantService} from './shared/services/etudiant.service';
import {ConfigPlanningService} from './shared/services/configPlanning.service';
import {SessionPlanningService} from './shared/services/sessionPlanning.service';
import {PeriodeSeanceService} from './shared/services/periodeSeance.service';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        LayoutsModule,
        ErrorModule,
        ManageUserModule,
        MatProgressButtonsModule.forRoot(),
        CrudExampleModule
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: ErrorStateMatcher,
            useClass: ShowOnDirtyErrorStateMatcher
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },

        AuthService,
        CanActivateViaAuthGuard,
        NotAuthorizedUserGuard,
        StorageService,
        UserService,
        CustomerService,
        ClientService,
        CanActivateViaAuthSuperAdminAuth,
        LoadPageService,
        MatiereService,
        EnseignantService,
        SessionService,
        UniteService,
        ConfigPlanningService,
        SessionPlanningService,
        PeriodeSeanceService,
        EtudiantService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
