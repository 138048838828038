import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {fadeAnimation} from '../../shared/animation';

@Component({
    selector: 'app-auth-page',
    templateUrl: './auth-page.component.html',
    styleUrls: ['./auth-page.component.less']
})
export class AuthPageComponent implements OnInit {

    adminAuth: boolean;
    admin: CustomElementRegistry;


    constructor(private route: ActivatedRoute, private router: Router) {

    }

    ngOnInit() {
        this.adminAuth = this.router.url.indexOf('admin') !== -1;
    }

}
