import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddUniteComponent} from './add-unite/add-unite.component';
import {ListUniteComponent} from './list-unite/list-unite.component';
import {UniteRoutingModule} from './unite-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {UniteService} from '../shared/services/unite.service';
import {MatiereService} from '../shared/services/matiere.service';
import {DisciplineService} from '../shared/services/discipline.service';
import {Unite} from '../shared/models/unite';

@NgModule({
    declarations: [AddUniteComponent, ListUniteComponent],
    imports: [
        CommonModule,
        UniteRoutingModule,
        FormsModule,
        SharedModule,
        NgSelectModule
    ],
    providers: [
        MatiereService,
        UniteService,
        DisciplineService
    ]
})
export class UniteModule {
}
