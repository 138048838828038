import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';
import {Config} from '../config';
import {catchError} from 'rxjs/operators';

@Injectable()
export class EnseignantService extends GenericService {

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
    }

    getAll() {
        const url = Config.baseUrlApi + '/enseignant';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));
    }

}
