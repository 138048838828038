import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';


@Injectable()
export class AuthService extends GenericService {

    constructor(private http: HttpClient, private stoarageService: StorageService) {
        super();
    }
}
