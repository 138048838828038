import {Component, OnInit} from '@angular/core';
import {UniteService} from '../../shared/services/unite.service';
import {Unite} from '../../shared/models/unite';
import {Session} from '../../shared/models/session';
import {MatiereService} from '../../shared/services/matiere.service';
import {Niveau} from '../../shared/models/niveau';
import {UniteSession} from '../../shared/models/UniteSession';
import {UniteSessionDiscipline} from '../../shared/models/UniteSessionDiscipline';
import {UniteSessionDisciplineMatiere} from '../../shared/models/UniteSessionDisciplineMatiere';
import Noty from 'noty';
import {SessionService} from '../../shared/services/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '../../shared/utils/utils';

declare let jQuery: any;

@Component({
    selector: 'app-add-session',
    templateUrl: './add-session.component.html',
    styleUrls: ['./add-session.component.css']
})
export class AddSessionComponent implements OnInit {

    unites: Unite[] = [];
    removedUnites: Unite[] = [];
    session: Session;
    niveaux: Niveau[] = [];
    semestres = [{label: 'Semestre 1', id: '1'}, {label: 'Semestre 2', id: '2'}];
    annees_universitaires = [{label: '2018/2019'}, {label: '2019/2020'}, {label: '2020/2021'}];
    isSubmitted = false;
    latestUniteSession: UniteSession;
    oneSelected = false;
    showIt = true;
    index_to_place = 0;
    allUnites: Unite[] = [];
    isEditMode = false;
    session_id: string;
    sessionEdit: Session;
    first_change_edit = false;

    constructor(private uniteService: UniteService,
                private router: Router,
                private route: ActivatedRoute,
                private matiereService: MatiereService, private sessionService: SessionService) {
        this.isEditMode = router.url.indexOf('edit') > 0;
        this.session_id = this.route.snapshot.paramMap.get('sessionId');
    }

    ngOnInit() {

        this.session = new Session();
        this.session.niveau = new Niveau();
        this.session.semestre = '1';
        this.session.annee = '2018/2019';
        if (this.session_id) {
            this.getSessionById(this.session_id);
        }
        this.getAllNiveaux();
        this.getAllUnites();
    }

    private getAllUnites() {
        this.uniteService.getAllUnites().subscribe((data: Unite[]) => {
            this.allUnites = data;
        });
    }

    getAllNiveaux() {
        this.enableLoader();

        this.matiereService.getAllNiveaux().subscribe(
            (data: any) => {
                this.niveaux = data;
                this.disableLoader();
            }
        );

    }

    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }

    onSelectUnite($event: any) {
        let uniteSession = new UniteSession();
        uniteSession.unite = this.unites.find(x => x.id == $event);
        uniteSession.unitesSessionsDisciplines = uniteSession.unite.uniteDiscipline.map(x => {
            let usd = new UniteSessionDiscipline();
            usd.discipline = x.discipline;

            usd.unitesSessionsDisciplinesMatieres = uniteSession.unite.uniteDiscipline.find(y => {
                return y.discipline.id == usd.discipline.id;
            }).unitesDisciplinesMatieres.map(z => {
                let usdm = new UniteSessionDisciplineMatiere();
                usdm.matiere = z.matiere;
                return usdm;
            });


            return usd;
        });
        this.latestUniteSession = uniteSession;
        this.oneSelected = true;

    }

    ajouterUnite() {
        if (!this.session.unitesSessions)
            this.session.unitesSessions = [];
        if (this.latestUniteSession) {
            this.removedUnites.splice(this.index_to_place, 0, this.latestUniteSession.unite);
            this.session.unitesSessions.splice(this.index_to_place + 1, 0, this.latestUniteSession);

            this.unites = this.unites.filter(x => {
                return x.id != this.latestUniteSession.unite.id;
            });
            this.latestUniteSession = null;
            this.showIt = false;
        } else if (this.unites && this.unites.length > 0 && this.oneSelected) {
            this.onSelectUnite(this.unites[0].id);
            let that = this;
            setTimeout(function () {
                that.removedUnites.splice(that.index_to_place, 0, that.latestUniteSession.unite);
                that.session.unitesSessions.splice(that.index_to_place + 1, 0, that.latestUniteSession);
                that.unites = that.unites.filter(x => {
                    return x.id != that.latestUniteSession.unite.id;
                });
                that.latestUniteSession = null;
            }, 5);
            this.showIt = false;

        }

    }

    removeUnite(i: number) {
        this.unites.push(this.session.unitesSessions[i].unite);
        this.session.unitesSessions.splice(i, 1);
        this.removedUnites.splice(i, 1);
        if (this.removedUnites.length == 0)
            this.showIt = true;
    }

    ajouterNouvelleUnite(i: number) {
        this.showIt = true;
        this.index_to_place = i;
    }

    onSelectNiveau($event: any) {
        let that = this;
        this.unites = this.allUnites.filter(unite => {
            return unite.niveau.id == that.session.niveau.id;
        });
        if (this.isEditMode && !this.first_change_edit)
            this.first_change_edit = true;
        else
            this.session.unitesSessions = [];
    }

    onSaveClick() {
        if (this.validatAll()) {
            this.enableLoader();
            this.sessionService.addSession(this.session).subscribe((data) => {
                if(!this.isEditMode){
                new Noty({
                    theme: 'metroui',
                    type: 'success',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Session ajoutée avec succès.'
                }).show();
                const niveau = this.session.niveau;
                this.session = new Session();
                this.session.niveau = niveau;
                this.session.semestre = '1';
                this.session.annee = '2018/2019';
                this.unites = this.unites.concat(this.removedUnites);
                this.removedUnites = [];
                this.showIt = true;
                this.disableLoader();
                }else{
                    Utils.showSuccessNoty('Session Modifée Avec Succès');
                    this.router.navigateByUrl('/session/list');
                }
            },(error)=>{
                Utils.showErrorNoty('Erreur !');
                this.router.navigateByUrl('/session/list');
            });
        }
        console.log(this.session);
    }

    validatAll() {
        if (!this.session.nom || !this.session.date_publication
            || !this.session.annee || !this.session.semestre ||
            !this.session.niveau.id || !this.session.unitesSessions || this.session.unitesSessions.length == 0) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Erreur : Merci de remplir tous les champs.'
            }).show();
            return false;
        }
        let sum_p_unites = 0;
        for (let uS of this.session.unitesSessions) {
            if (!uS.ponderation || uS.ponderation == 0 || !uS.unitesSessionsDisciplines || uS.unitesSessionsDisciplines.length == 0) {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Erreur : Merci de remplir tous les champs.'
                }).show();
                return false;
            }
            sum_p_unites += uS.ponderation;
            let sum_p_disciplines = 0;
            for (let uSD of uS.unitesSessionsDisciplines) {
                if (!uSD.ponderation || uSD.ponderation == 0 || !uSD.unitesSessionsDisciplinesMatieres
                    || uSD.unitesSessionsDisciplinesMatieres.length == 0) {
                    new Noty({
                        theme: 'metroui',
                        type: 'error',
                        layout: 'topRight',
                        timeout: 5000,
                        progressBar: true,
                        text: 'Erreur : Merci de remplir tous les champs.'
                    }).show();
                    return false;
                }
                sum_p_disciplines += uSD.ponderation;
                let sum_p_matieres = 0;
                for (let uSDM of uSD.unitesSessionsDisciplinesMatieres) {
                    console.log(sum_p_matieres);
                    if (!uSDM.ponderation || uSDM.ponderation == 0) {
                        new Noty({
                            theme: 'metroui',
                            type: 'error',
                            layout: 'topRight',
                            timeout: 5000,
                            progressBar: true,
                            text: 'Erreur : Merci de remplir tous les champs.'
                        }).show();
                        return false;
                    }
                    sum_p_matieres += uSDM.ponderation;
                }
                console.log(sum_p_matieres);
                if (sum_p_matieres != 100) {
                    new Noty({
                        theme: 'metroui',
                        type: 'error',
                        layout: 'topRight',
                        timeout: 5000,
                        progressBar: true,
                        text: 'La somme des pondérations de la discipline ' + uSD.discipline.titre + ' est différente de 100'
                    }).show();
                    return false;
                }
            }
            if (sum_p_disciplines != 100) {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'La somme des pondérations de l\'unité ' + uS.unite.nom + ' est différente de 100'
                }).show();
                return false;
            }

        }
        if (sum_p_unites != 100) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'La somme des pondérations de des unités est différente de 100'
            }).show();
            return false;
        }

        return true;
    }


    getSessionById(session_id: string) {
        Utils.enableLoader();
        let s: Session;
        this.sessionService.getSessionExamenById(this.session_id).subscribe((data: Session) => {
            // console.log(data);
            s = data;
            console.log(s);
            // this.session.unitesSessions=data.unitesSessions;
            // this.session.semestre=data.semestre;
            // this.session.nom=data.nom;
            // this.session.niveau.id=data.niveau.id;
            // this.session.niveau.label=data.niveau.label;
            // this.session.annee=data.annee;
            // this.session.date_publication=data.date_publication;
            console.log(data);
            this.sessionEdit = s;
            this.session = s;
            if (!this.session) {
                Utils.disableLoader();
                Utils.showErrorNoty('Erreur, session introuvable');
                this.router.navigateByUrl('/session/list');
            }
            Utils.disableLoader();
        }, (error) => {
            Utils.disableLoader();
            Utils.showErrorNoty('Erreur, modification impossible');
            this.router.navigateByUrl('/session/list');
        });
    }
}
