import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgBusyModule} from 'ng-busy';
import {StorageService} from './services/storage.service';
import {MomentModule} from 'ngx-moment';
import {NgSelect2Component} from './components/ng-select2/ng-select2.component';
import {NgDropdownCustomComponent} from './components/ng-dropdown-custom/ng-dropdown-custom.component';
import {WidgetChartComponent} from './components/widget-chart/widget-chart.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgBusyModule,
        MomentModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgBusyModule,
        MomentModule,
        NgSelect2Component,
        NgDropdownCustomComponent,
        WidgetChartComponent
    ],
    declarations: [
        NgSelect2Component,
        NgDropdownCustomComponent,
        WidgetChartComponent
    ],
    providers: [StorageService]
})
export class SharedModule {

}
