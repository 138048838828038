import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListExampleComponent} from './list-example/list-example.component';
import {AddExampleComponent} from './add-example/add-example.component';

const routes: Routes = [
    {
        path: 'list',
        component: ListExampleComponent
    },
    {
        path: 'add',
        component: AddExampleComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CrudExampleRoutingModule {
}
