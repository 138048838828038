import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddConfigPlanningComponent } from './add-config-planning/add-config-planning.component';
import { ListConfigPlanningComponent } from './list-config-planning/list-config-planning.component';
import {ConfigPlanningRoutingModule} from './config-planning-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [AddConfigPlanningComponent, ListConfigPlanningComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ConfigPlanningRoutingModule
  ]
})
export class ConfigPlanningModule { }
