import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {UserService} from './services/user.service';

declare var swal: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    ingnoredInterceptedUris = ['factoryUploadTmpDocuments'];

    constructor(private userService: UserService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let interceptRequest = true;
        this.ingnoredInterceptedUris.forEach((function (ingnoredInterceptedUri) {
            interceptRequest = interceptRequest && !request.url.includes(ingnoredInterceptedUri);
        }));
        request = interceptRequest ? request.clone({
            setHeaders: this.userService.getToken() ? {
                Authorization: `Bearer ${this.userService.getToken()}`,
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json, text/plain, */*'
            } : {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json, text/plain, */*'
            }
        }) : request;

        console.log(request);
        return next.handle(request);
        /*.pipe(catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // Session Expiré
              this.userService.clear();
              this.router.navigate(['/auth']);
            } else if (err.status > 299 || err.status < 200) {
            }
          }
          return of(HttpErrorResponse);
        }))as Observable<HttpEvent<any>>;*/
    }
}
