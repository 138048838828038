import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddDisciplineComponent} from './add-discipline/add-discipline.component';
import {ListDisciplineComponent} from './list-discipline/list-discipline.component';
import {DisciplineRoutingModule} from './discipline-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatiereService} from '../shared/services/matiere.service';
import {DisciplineService} from '../shared/services/discipline.service';

@NgModule({
    declarations: [AddDisciplineComponent, ListDisciplineComponent],
    imports: [
        CommonModule,
        DisciplineRoutingModule,
        FormsModule,
        SharedModule,
        NgSelectModule
    ],
    providers: [
        MatiereService,
        DisciplineService
    ]
})
export class DisciplineModule {
}
