import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ListSessionComponent} from './list-session/list-session.component';
import {AddSessionComponent} from './add-session/add-session.component';

const routes: Routes = [
    {
        path: 'list',
        component: ListSessionComponent
    },
    {
        path: 'add',
        component: AddSessionComponent
    },
    {
        path: 'edit/:sessionId',
        component: AddSessionComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)
        ],
    exports: [RouterModule]
})
export class SessionRoutingModule {
}
