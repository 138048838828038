import {Component, OnInit} from '@angular/core';
import {SpecificationChefUniteRequest, Unite} from '../../shared/models/unite';
import {Utils} from '../../shared/utils/utils';
import {UniteService} from '../../shared/services/unite.service';
import {EnseignantService} from '../../shared/services/enseignant.service';
import {Enseignant} from '../../shared/models/enseignant';

declare let jQuery: any;
declare let swal: any;

@Component({
    selector: 'app-list-unite',
    templateUrl: './list-unite.component.html',
    styleUrls: ['./list-unite.component.css']
})
export class ListUniteComponent implements OnInit {
    enseignants: Enseignant[];

    unites: Unite[];
    unite_selected: Unite;
    selected_index: number;

    constructor(private uniteService: UniteService, private enseignantsService: EnseignantService) {
    }


    ngOnInit() {
        this.getAllUnites();
        this.getAllEnseignants();
    }

    getAllUnites() {
        Utils.enableLoader();
        this.uniteService.getAllUnites().subscribe(
            (data: any) => {
                this.unites = data;
                console.log(this.unites);
                Utils.initializeDataTables('datatable', 20, 6);
                setTimeout(this.disableLoader, 500);

            },
            (error) => {
                Utils.showErrorNoty('Erreur !');
                Utils.disableLoader();
            }
        );
        setTimeout(function () {
            Utils.disableLoader();
        }, 5000);
    }


    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }

    deleteUnite(index) {
        let that = this;
        swal({
                title: 'Êtes vous sûrs?',
                text: 'Vous ne pouvez plus reverser la suppression!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn-danger',
                confirmButtonText: 'Oui, Supprimer !',
                closeOnConfirm: true
            },
            function () {
                Utils.enableLoader();

                that.uniteService.deleteUnite(that.unites[index].id).subscribe(
                    () => {
                        console.log(that.unites);
                        that.unites = that.unites.filter(unite => unite.id != that.unites[index].id);
                        console.log(that.unites);

                        Utils.initializeDataTables('datatable', 100, 8);
                        Utils.disableLoader();
                        Utils.showSuccessNoty('L\'unité a été supprimée');
                    },
                    () => {
                        Utils.disableLoader();
                        Utils.showErrorNoty('L\'unité n\'a pas été supprimée');
                    }
                );
                setTimeout(function () {
                    Utils.disableLoader();
                }, 5000);
            });
    }

    specifierChefUnite(i: number) {
        this.selected_index=i;
        if (!this.unites[i].chef_unite)
            this.unites[i].chef_unite = new Enseignant();
        this.unite_selected = this.unites[i];
        jQuery('#modalChefUnite').modal('toggle');

    }

    getAllEnseignants() {
        this.enseignantsService.getAll().subscribe((data: Enseignant[]) => {
            this.enseignants = data;
        }, (error) => {
        });
    }

    specifyChefUnite(isSet) {
        Utils.enableLoader();
        let req = new SpecificationChefUniteRequest();
        req.unite_id = this.unite_selected.id;
        if (isSet)
            req.chef_unite_id = this.unite_selected.chef_unite.id;
        this.uniteService.specifyChefUnite(req).subscribe((data) => {
            Utils.disableLoader();
            if(req.chef_unite_id)
                Utils.showSuccessNoty('Chef d\'unité spécifié');
            else
                Utils.showSuccessNoty('Chef d\'unité réinitialisé');
            jQuery('#modalChefUnite').modal('toggle');
            this.unites[this.selected_index]=data;
            this.unite_selected=null;
            Utils.initializeDataTables('datatable', 40, 8);

        }, (error => {
            Utils.disableLoader();
            Utils.showErrorNoty('Erreur');
            jQuery('#modalChefUnite').modal('toggle');
            this.unite_selected=null;


        }));

    }
}
