import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {User} from '../models/user/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage.service';
import {Config} from '../config';
import {catchError} from 'rxjs/operators';
import {Matiere} from '../models/matiere';
import {Credentials} from '../models/user/credentials';

@Injectable()
export class CustomerService extends GenericService {
    loggedAdmin: User;

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
        this.loggedAdmin = <User>storageService.read(Config.userKey);
    }

    getAll() {
        const url = Config.baseUrlApi + '/customer';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));
    }

    login(credentials: Credentials) {
        const url = Config.baseUrlApi + '/customer/guest/login';
        return this.http.post<any>(url, credentials)
            .pipe(catchError(this.handleErrors));
    }

}
