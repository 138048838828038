import {Discipline} from './discipline';
import {Matiere} from './matiere';
import {UniteSessionDisciplineMatiere} from './UniteSessionDisciplineMatiere';
import {MoyenneUnite} from './MoyenneUnite';
import {Session} from './session';
import {Etudiant} from './Etudiant';

export class MoyenneSession {
    id: string;
    moyenne: number;
    session: Session;
    etudiant: Etudiant;
    moyennesUnites: MoyenneUnite[];

}
