import {Enseignant, EnseignantRequest} from './enseignant';

export class Cours {
    id: string;
    titre: string;
    coursType: CoursType;
    enseignant: Enseignant;
    coursObjectif: CoursObjectif[];
    initializedObjectives = false;
}

export class CoursRequest {
    id: string;
    titre: string;
    coursType: CoursType;
    enseignant: EnseignantRequest;
}

export class CoursType {
    id: string;
    label: string;
}

export class CoursObjectif {
    id: string;
    titre: string;
    isNew = false;

    static toCoursObjectif(coursObjectif: any): CoursObjectif {

        const id = coursObjectif;
        coursObjectif = new CoursObjectif();
        coursObjectif.id = id;
        return coursObjectif;
    }

}




