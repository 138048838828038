import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {Config} from '../config';
import {StorageService} from './storage.service';
import {catchError} from 'rxjs/operators';
import {Client} from '../models/client';

@Injectable()
export class UserService extends GenericService {
    loggedClient: Client;

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
        this.loggedClient = <Client>storageService.read(Config.userKey);
    }

    isLoggedIn() {
        return this.storageService.read(Config.userTokenKey) != null;
    }

    getToken() {
        return <string>this.storageService.read(Config.userTokenKey);
    }


    clear() {
        this.storageService.removeAll();
    }

    storeUserAccessToken(accessToken: string) {
        this.storageService.write(Config.userTokenKey, accessToken);
    }

    storeUser(client: Client) {
        this.storageService.write(Config.userKey, client);
    }


    getCurrentClient() {
        const url = Config.baseUrlApi + '/customer/me';

        return this.http.get<any>(url)
            .pipe(catchError(this.handleErrors));
    }


    getAuthorities(role: string) {
        return this.loggedClient.authorities === role;
    }
}
