import {Component, OnInit} from '@angular/core';
import {Session} from '../../shared/models/session';
import {SessionService} from '../../shared/services/session.service';
import {Utils} from '../../shared/utils/utils';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import 'bootstrap-fileinput';
import * as fs from 'file-saver';
import {Etudiant} from '../../shared/models/Etudiant';
import {MoyenneSession} from '../../shared/models/MoyenneSession';
import {MoyenneUnite} from '../../shared/models/MoyenneUnite';
import {MoyenneDiscipline} from '../../shared/models/MoyenneDiscipline';
import {NoteMatiere} from '../../shared/models/NoteMatiere';
import Noty from 'noty';
import {EtudiantService} from '../../shared/services/etudiant.service';
import {Log} from '@angular/core/testing/src/logger';

declare let jQuery: any;
declare let swal: any;

@Component({
    selector: 'app-list-session',
    templateUrl: './list-session.component.html',
    styleUrls: ['./list-session.component.css']
})

export class ListSessionComponent implements OnInit {

    sessions: Session[] = [];
    session_upload: Session;
    notes_file: any;
    fileUploaded = false;
    etudiants: Etudiant[];
    arrayBuffer: any;
    showInputFile = true;
    session_calcul: Session;

    constructor(private sessionService: SessionService, private etudiantService: EtudiantService) {
    }

    ngOnInit() {
        this.getAllSessions();
        let that = this;
        jQuery('#input-notes').fileinput({
            allowedFileExtensions: ['xlsx'],
            showUpload: false,
            dropZoneEnabled: false,
            maxFileCount: 1,
        }).on('fileloaded', function (event, file, previewId, index, reader) {
            console.log('hello');
            that.showInputFile = false;
            that.uploadNotes(file);
        });
    }

    //TODO Ajouter statut of released or not
    //TODO Ajouter statut of pourcentage de notes?
    //TODO Ajotuer statut of notes calculés ou pas
    private getAllSessions() {
        Utils.enableLoader();
        this.sessionService.getAllSessions().subscribe((data: Session[]) => {
                this.sessions = data;
                Utils.initializeDataTables('dataTable', 20, 6);
                Utils.disableLoader();
            },
            (error)=>{
                Utils.showErrorNoty('error');
                Utils.disableLoader();
            }
        );
    }

    generateExcel(i: number) {
        this.createExcel(this.sessions[i]);
    }

    createExcel(session: Session) {
        Utils.enableLoader();
        let that = this;
        this.etudiantService.getAllEtudiantsByNiveau(session.niveau).subscribe((data) => {
            let etudiants = data;
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Notes');
            let session_name_row = worksheet.addRow(['Session', session.nom, 'Niveau', session.niveau.label, 'Année',
                session.annee, 'Semsestre', session.semestre]);
            let unites = session.unitesSessions.map(us => new UniteObject(us.unite.nom, us.ponderation,
                us.unitesSessionsDisciplines.map(usd => usd.unitesSessionsDisciplinesMatieres.length).reduce(this.add)
            ));


            // @ts-ignore
            let disciplines = [].concat(...session.unitesSessions.map(us =>
                us.unitesSessionsDisciplines.map(usd => new DisciplineObject(usd.discipline.titre
                    , usd.ponderation, usd.unitesSessionsDisciplinesMatieres.length))));
            let matieres = [].concat(...[].concat(...session.unitesSessions.map(us => us.unitesSessionsDisciplines.map
            (usd => [].concat(...usd.unitesSessionsDisciplinesMatieres
                .map(usdm => new MatiereObject(usdm.matiere.nom, usdm.ponderation)))))));
            let row_disciplines = ['', '', ''];
            for (let discipline of disciplines) {
                row_disciplines.push(discipline.nom + ' (' + discipline.ponderation + ')');
                row_disciplines = row_disciplines.concat(Array<string>(discipline.n_children - 1).fill(''));
            }
            let row_unites = ['', '', ''];
            for (let unite of unites) {
                row_unites.push(unite.nom + ' (' + unite.ponderation + ')');
                row_unites = row_unites.concat(Array<string>(unite.n_children - 1).fill(''));
            }


            // console.log(matieres);
            // console.log(session.unitesSessions.map(us => us.unite.nom));
            // console.log(session.unitesSessions.map(us => us.unitesSessionsDisciplines.map(usd => usd.discipline.titre)));
            // console.log(session.unitesSessions.map(us => us.unitesSessionsDisciplines.map(usd => usd.unitesSessionsDisciplinesMatieres.map(usdm => usdm.matiere.nom))));
            let unitesRow = worksheet.addRow(row_unites);
            unitesRow.eachCell((cell, number) => {
                if (number > 3) {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                }
            });
            let i = 3;
            for (let unite of unites) {
                let column_begin = String.fromCharCode(65 + i);
                let column_end = String.fromCharCode(65 + i + unite.n_children - 1);
                worksheet.mergeCells(column_begin + '2:' + column_end + '2');
                i += unite.n_children;
            }

            let disciplinesRow = worksheet.addRow(row_disciplines);
            disciplinesRow.eachCell((cell, number) => {
                if (number > 3) {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                }
            });
            let j = 3;
            for (let discipline of disciplines) {
                let column_begin = String.fromCharCode(65 + j);
                let column_end = String.fromCharCode(65 + j + discipline.n_children - 1);
                worksheet.mergeCells(column_begin + '3:' + column_end + '3');
                j += discipline.n_children;
            }

            const mats = matieres.map(matiere => matiere.nom + ' (' + matiere.ponderation + ')');
            const n_mats = mats.length;
            let matieresRow = worksheet.addRow(['Nom', 'Prenom', 'CIN'].concat(mats));
            for (let etudiant of etudiants) {
                let last_row = worksheet.addRow([etudiant.nom, etudiant.prenom, etudiant.cin].concat(Array(n_mats).join('.').split('.')));
                last_row.eachCell((cell, number) => {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                });

            }
            matieresRow.eachCell((cell, number) => {
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                };
            });

            const title = 'Modèle des Notes: ' + session.nom;
            workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                fs.saveAs(blob, 'Modele_Notes_Session_Examens.xlsx');
            });
            Utils.disableLoader();
        }, (error) => {
            Utils.showErrorNoty('Erreur!');
        });
        setTimeout(function () {
            Utils.disableLoader();
        }, 5000);
    }

    open() {
    }

    Upload(event) {
        this.notes_file = event.target.files[0];

        let baseContext = this;
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;

        };
        fileReader.readAsArrayBuffer(this.notes_file);
        this.fileUploaded = true;
        this.uploadNotes(event);
    }


    add(accumulator, a) {
        return accumulator + a;
    }

    startUploadNotes(i: number) {
        let that = this;

        this.notes_file = null;
        this.arrayBuffer = null;
        this.session_upload = this.sessions[i];
    }

    uploadNotes(file) {
        this.notes_file = file;
        let that = this;
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
            this.arrayBuffer = fileReader.result;

            new Excel.Workbook().xlsx
                .load(fileReader.result)
                .then((workbook) => {
                    that.transform_workbook_to_json(workbook);
                });
        };
        fileReader.readAsArrayBuffer(this.notes_file);

    }

    transform_workbook_to_json(workbook) {

        Utils.enableLoader('cardImport');
        let worksheet = workbook.getWorksheet(1);
        let rows = [];
        let that = this;

        let session = this.session_upload;
        let n_matieres = this.validateFormat(session, worksheet);
        if (!n_matieres) {
            return false;
        }


        let moyennes_sessions = [];
        let r_count = worksheet.actualRowCount;
        worksheet.eachRow(function (row_1, rowNumber) {
            if (rowNumber > 4) {
                let row = row_1.values;
                if (!that.validateRow(row, n_matieres, rowNumber))
                    return false;

                let i = 4;
                let cin = row[3];
                const e = new Etudiant();
                e.cin = cin;
                const moyenne_session = new MoyenneSession();
                moyenne_session.etudiant = e;
                moyenne_session.moyenne = null;
                moyenne_session.session = that.session_upload;
                moyenne_session.moyennesUnites = [];
                for (let unite_session of that.session_upload.unitesSessions) {
                    const moyenne_unite = new MoyenneUnite();
                    moyenne_unite.moyenne = null;
                    moyenne_unite.uniteSession = unite_session;
                    moyenne_unite.moyennesDisciplines = [];
                    for (let unite_session_discipline of unite_session.unitesSessionsDisciplines) {
                        const moyenne_discipline = new MoyenneDiscipline();
                        moyenne_discipline.moyenne = null;
                        moyenne_discipline.uniteSessionDiscipline = unite_session_discipline;
                        moyenne_discipline.notesMatieres = [];
                        for (let unite_session_discipline_matiere of unite_session_discipline.unitesSessionsDisciplinesMatieres) {
                            const note_matiere = new NoteMatiere();
                            note_matiere.uniteSessionDisciplineMatiere = unite_session_discipline_matiere;
                            note_matiere.note = row[i];
                            i += 1;
                            moyenne_discipline.notesMatieres.push(note_matiere);
                        }
                        moyenne_unite.moyennesDisciplines.push(moyenne_discipline);
                    }
                    moyenne_session.moyennesUnites.push(moyenne_unite);

                }
                moyennes_sessions.push(moyenne_session);

                if (rowNumber == r_count) {
                    that.sessionService.uploadNotes(moyennes_sessions).subscribe((data: String[]) => {
                        Utils.disableLoader('cardImport');
                        let n_invalids = data.length;
                        let n_total = moyennes_sessions.length;
                        let n_valids = n_total - n_invalids;
                        new Noty({
                            theme: 'metroui',
                            type: 'success',
                            layout: 'topRight',
                            timeout: 5000,
                            progressBar: true,
                            text: 'Les notes de ' + n_valids + ' étudiants ont été soumis avec succès.'
                        }).show();
                        if (data.length > 0) {
                            new Noty({
                                theme: 'metroui',
                                type: 'warning',
                                layout: 'topRight',
                                timeout: 10000,
                                progressBar: true,
                                text: 'Erreur: Les notes de ' + n_invalids + ' étudiants n\'ont pas été soumis: ' +
                                    data
                            }).show();

                        }
                        jQuery('#input-notes').fileinput('clear');
                    });

                }
            }
        });
        setTimeout(function () {
            Utils.disableLoader('cardImport');
        }, 5000);
    }

    validateFormat(session: Session, worksheet: any) {
        let row_1 = worksheet.getRow(1).values;
        if (row_1[2] != session.nom || row_1[4] != session.niveau.label || row_1[6] != session.annee || row_1[8] != session.semestre) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Format Excel incorrect, Merci de retélécharger le dernier modèle excel'
            }).show();
            jQuery('#input-notes').fileinput('clear');
            Utils.disableLoader('cardImport');
            return false;
        }


        let matieres = [].concat(...[].concat(...session.unitesSessions.map(us => us.unitesSessionsDisciplines.map
        (usd => [].concat(...usd.unitesSessionsDisciplinesMatieres
            .map(usdm => new MatiereObject(usdm.matiere.nom, usdm.ponderation)))))));
        let format_excel = ['Nom','Prenom','CIN'].concat(matieres.map(matiere => matiere.nom + ' (' + matiere.ponderation + ')'));
        let row_4 = worksheet.getRow(4).values;
        for (let i = 0; i < format_excel.length; i += 1) {
            if (row_4[i + 1] != format_excel[i]) {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Format Excel incorrect, Merci de retélécharger le dernier modèle excel'
                }).show();
                jQuery('#input-notes').fileinput('clear');
                Utils.disableLoader('cardImport');
                return false;
            }
        }
        return format_excel.length - 1;
    }

    validateRow(row, n_matieres, row_number) {
        if (row.length != n_matieres + 2) {
            console.log('problem in : row length ' + row + ' ' + row.length);

            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Format Excel incorrect, Vérifiez les notes. (ligne : ' + row_number + ' )'
            }).show();
            jQuery('#input-notes').fileinput('clear');
            Utils.disableLoader('cardImport');
            return false;
        }
        for (let i = 4; i < row.length; i += 1) {
            if (isNaN(row[i]) || row[i] < 0) {
                console.log('problem in : ' + row[i]);
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Format Excel incorrect, Vérifiez les notes. (ligne : ' + row_number + ' )'
                }).show();
                jQuery('#input-notes').fileinput('clear');
                Utils.disableLoader('cardImport');
                return false;
            }
        }
        return true;
    }

    calculerNotes(i: number) {
        Utils.enableLoader();
        this.session_calcul = this.sessions[i];
        this.sessionService.calculerMoyennes(this.sessions[i]).subscribe((data: Etudiant[]) => {
            console.log(data);
            new Noty({
                theme: 'metroui',
                type: 'success',
                layout: 'topRight',
                timeout: 2000,
                progressBar: true,
                text: 'Moyennes calculés'
            }).show();
            if (data.length > 0) {
                this.etudiants = data;

                let mymodal = jQuery('#modalUncalculated');
                mymodal.on('shown.bs.modal', function (e) {
                    Utils.initializeDataTables('table_etudiants', 60, 3);
                });
                mymodal.modal('toggle');
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 3000,
                    progressBar: true,
                    text: data.length + ' étudiant(s) du niveau n\'ont pas de notes. '
                }).show();
            }
            Utils.disableLoader();
        }, error => {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Une erreur est survenue.'
            }).show();
            Utils.disableLoader();
        });

    }

    supprimerSession(i: number) {
        let that = this;
        swal({
                title: "Êtes vous sûrs?",
                text: "Vous ne pouvez plus reverser la suppression!",
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Oui, Supprimer !",
                closeOnConfirm: true
            },
            function(){
                Utils.enableLoader();
                that.sessionService.deleteSession(that.sessions[i].id).subscribe(
                    () => {
                        that.sessions=that.sessions.filter(session=>session.id!=that.sessions[i].id);

                        Utils.initializeDataTables('datatable', 100, 8);
                        Utils.disableLoader();
                        Utils.showSuccessNoty("La session a été supprimée");
                    },
                    () => {
                        Utils.disableLoader();
                        Utils.showErrorNoty("La session n'a pas été supprimée");
                    }
                );
                setTimeout(function(){Utils.disableLoader();},5000);
            });
    }

    telechargerResultat(i: number) {
        Utils.enableLoader();
        this.sessionService.getAllMoyennesSessionsBySession(this.sessions[i].id).subscribe(data=>{
            let moyennes_sessions = data;
                let session = this.sessions[i];
                let unites_disciplines_s = session.unitesSessions.map(us => new UniteObject(us.unite.nom, us.ponderation,
                    us.unitesSessionsDisciplines.length));

                let unites_matieres_s = session.unitesSessions.map(us => new UniteObject(us.unite.nom, us.ponderation,
                    us.unitesSessionsDisciplines.map(usd => usd.unitesSessionsDisciplinesMatieres.length).reduce(this.add)
                ));


                // @ts-ignore
                let disciplines = [].concat(...session.unitesSessions.map(us =>
                    us.unitesSessionsDisciplines.map(usd => new DisciplineObject(usd.discipline.titre
                        , usd.ponderation, usd.unitesSessionsDisciplinesMatieres.length))));
                let matieres = [].concat(...[].concat(...session.unitesSessions.map(us => us.unitesSessionsDisciplines.map
                (usd => [].concat(...usd.unitesSessionsDisciplinesMatieres
                    .map(usdm => new MatiereObject(usdm.matiere.nom, usdm.ponderation)))))));


                const workbook = new Excel.Workbook();

                //Sheet Unités
                let worksheet_unites = workbook.addWorksheet('Unités');

                let session_name_unites_row = worksheet_unites.addRow(['Session', session.nom, 'Niveau', session.niveau.label, 'Année',
                    session.annee, 'Semsestre', session.semestre]);
                session_name_unites_row.eachCell((cell, number) => {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                });

                let header_row_unites = ['Nom', 'Prenom', 'CIN'];
                for (let unite of unites_disciplines_s) {
                    header_row_unites.push(unite.nom + ' (' + unite.ponderation + ')');
                }
                header_row_unites= header_row_unites.concat('Moyenne Générale');

                let unitesRow = worksheet_unites.addRow(header_row_unites);
                unitesRow.eachCell((cell, number) => {
                    if (number > 3) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    }
                });

                for(let ms of moyennes_sessions)
                {
                    let moyennes_unites = ms.moyennesUnites.map(mu=>""+mu.moyenne);
                    let moyenne_row = worksheet_unites.addRow([ms.etudiant.nom.toUpperCase() , ms.etudiant.prenom.toUpperCase()
                        , ms.etudiant.cin].concat(moyennes_unites).concat([""+ms.moyenne]));
                    moyenne_row.eachCell((cell, number) => {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    });
                }
                //***********************************Disciplines
                let worksheet_disciplines = workbook.addWorksheet('Disciplines');
                let session_name_disciplines_row = worksheet_disciplines.addRow(['Session', session.nom, 'Niveau', session.niveau.label, 'Année',
                    session.annee, 'Semsestre', session.semestre]);
                session_name_disciplines_row.eachCell((cell, number) => {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                });
                let header_row_unites2 = ['', '', ''];
                for (let unite of unites_disciplines_s) {
                    header_row_unites2.push(unite.nom + ' (' + unite.ponderation + ')');
                    header_row_unites2 = header_row_unites2.concat(Array<string>(unite.n_children - 1).fill(''));
                }
                header_row_unites2= header_row_unites2.concat('');
                let unitesRow2 = worksheet_disciplines.addRow(header_row_unites2);

                unitesRow2.eachCell((cell, number) => {
                    if (number > 3) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    }
                });
                let row_disciplines = ['Nom', 'Prenom', 'CIN'];
                for (let discipline of disciplines) {
                    row_disciplines.push(discipline.nom + ' (' + discipline.ponderation + ')');
                }
                row_disciplines=row_disciplines.concat(['Moyenne Générale']);
                let disciplinesRow = worksheet_disciplines.addRow(row_disciplines);
                disciplinesRow.eachCell((cell, number) => {
                    if (number > 3) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    }
                });
                worksheet_disciplines.mergeCells('A2:C2');
                let j = 3;
                for (let unite of unites_disciplines_s) {
                    let column_begin = String.fromCharCode(65 + j);
                    let column_end = String.fromCharCode(65 + j + unite.n_children - 1);
                    worksheet_disciplines.mergeCells(column_begin + '2:' + column_end + '2');
                    j += unite.n_children;
                }

                for(let ms of moyennes_sessions)
                {
                    let moyennes_disciplines = [].concat(...ms.moyennesUnites.map(mu =>
                        mu.moyennesDisciplines.map(md => ""+md.moyenne )));


                    let moyenne_row = worksheet_disciplines.addRow([ms.etudiant.nom.toUpperCase() , ms.etudiant.prenom.toUpperCase()
                        , ms.etudiant.cin].concat(moyennes_disciplines).concat([""+ms.moyenne]));
                    moyenne_row.eachCell((cell, number) => {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    });
                }
                //***********************************Matieres
                let worksheet_matieres = workbook.addWorksheet('Matieres');
                let session_name_matieres_row = worksheet_matieres.addRow(['Session', session.nom, 'Niveau', session.niveau.label, 'Année',
                    session.annee, 'Semsestre', session.semestre]);
                session_name_matieres_row.eachCell((cell, number) => {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                });


                let header_row_unites3 = ['', '', ''];
                for (let unite of unites_matieres_s) {
                    header_row_unites3.push(unite.nom + ' (' + unite.ponderation + ')');
                    header_row_unites3 = header_row_unites3.concat(Array<string>(unite.n_children - 1).fill(''));
                }
                let unitesRow3 = worksheet_matieres.addRow(header_row_unites3);

                unitesRow3.eachCell((cell, number) => {
                    if (number > 3) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    }
                });

                let row_disciplines3 = ['', '', ''];
                for (let discipline of disciplines) {
                    row_disciplines3.push(discipline.nom + ' (' + discipline.ponderation + ')');
                    row_disciplines3 = row_disciplines3.concat(Array<string>(discipline.n_children - 1).fill(''));
                }
                let disciplinesRow3 = worksheet_matieres.addRow(row_disciplines3);
                disciplinesRow3.eachCell((cell, number) => {
                    if (number > 3) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    }
                });
                worksheet_matieres.mergeCells('A2:C2');
                worksheet_matieres.mergeCells('A3:C3');
                const mats = matieres.map(matiere => matiere.nom + ' (' + matiere.ponderation + ')');
                const n_mats = mats.length;
                let matieresRow = worksheet_matieres.addRow(['Nom', 'Prenom', 'CIN'].concat(mats).concat('Moyenne'));

                matieresRow.eachCell((cell, number) => {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                    };
                });
                let z = 3;
                for (let unite of unites_matieres_s) {
                    let column_begin = String.fromCharCode(65 + z);
                    let column_end = String.fromCharCode(65 + z + unite.n_children - 1);
                    worksheet_matieres.mergeCells(column_begin + '2:' + column_end + '2');
                    z += unite.n_children;
                }
                let x = 3;
                for (let discipline of disciplines) {
                    let column_begin = String.fromCharCode(65 + x);
                    let column_end = String.fromCharCode(65 + x + discipline.n_children - 1);
                    worksheet_matieres.mergeCells(column_begin + '3:' + column_end + '3');
                    x += discipline.n_children;
                }

                for(let ms of moyennes_sessions)
                {
                    let moyennes_matieres = [].concat(...[].concat(...ms.moyennesUnites.map(mu => mu.moyennesDisciplines.map
                    (md => [].concat(...md.notesMatieres
                        .map(note =>note.note ))))));

                    let note_row = worksheet_matieres.addRow([ms.etudiant.nom.toUpperCase() , ms.etudiant.prenom.toUpperCase()
                        , ms.etudiant.cin].concat(moyennes_matieres).concat([""+ms.moyenne]));
                    note_row.eachCell((cell, number) => {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}
                        };
                    });
                }



                const title = 'Résultats : ' + session.nom;
                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    fs.saveAs(blob, 'Resultats_'+session.nom.replace(/[^\w]/g,'_')+'.xlsx');
                });


                Utils.disableLoader();

            },
            (error)=>{
                Utils.disableLoader();
                Utils.showErrorNoty("Erreur");
            });
        setTimeout(function(){
        Utils.disableLoader();},5000);


    }
}


class UniteObject {
    nom: string;
    ponderation: number;
    n_children = 0;

    constructor(nom, ponderation, n_children) {
        this.nom = nom;
        this.ponderation = ponderation;
        this.n_children = n_children;

    }
}

class DisciplineObject {
    nom: string;
    ponderation: number;
    n_children: number = 0;

    constructor(nom, ponderation, n_children) {
        this.nom = nom;
        this.ponderation = ponderation;
        this.n_children = n_children;
    }


}

class MatiereObject {
    nom: string;
    ponderation: number;

    constructor(nom, ponderation) {
        this.nom = nom;
        this.ponderation = ponderation;
    }
}
