import Noty from 'noty';

/**
 * Created by Abbes on 30/06/2017.
 */

declare var jQuery: any;


export class Utils {


    public static zero(n: number) {
        if (n < 10) {
            return '0' + n;
        }
        return n;
    }


    private static groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }


    public static groupByM(array, f) {
        let groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    }

    static enableLoader(id = 'mainCard') {
        const loader = jQuery('#' + id);

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    static disableLoader(id = 'mainCard') {
        const loader = jQuery('#' + id);

        loader.unblock();
    }

    static initializeDataTables(className, timout: number, columnNumber: number, orderBy ?: number) {
        console.log('init Datatable');
        // Basic datatable
        // if (jQuery.fn.DataTable.isDataTable('.' + className)) {
        //     jQuery('.' + className).dataTable().fnDestroy();
        // }
        if (jQuery.fn.DataTable.isDataTable('.' + className)) {
            jQuery('.' + className).dataTable().fnDestroy();
        }
        setTimeout(function () {
                if (jQuery.fn.DataTable.isDataTable('.' + className)) {
                    jQuery('.' + className).dataTable().fnDestroy();
                }
                const tableListStation = jQuery('.' + className);
                // Setting datatable defaults
                jQuery.extend(jQuery.fn.dataTable.defaults, {
                    autoWidth: false,
                    columnDefs: [{
                        orderable: false,
                        width: '100px',
                        targets: [columnNumber - 1]
                    }],
                    dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
                    language: {
                        search: '<span>Filter:</span> _INPUT_',
                        lengthMenu: '<span>Show:</span> _MENU_',
                        paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
                    },
                    drawCallback: function () {
                        jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
                    },
                    preDrawCallback: function () {
                        jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
                    }
                });

                // console.log("order By " + orderBy);


                // Basic datatable
                tableListStation.DataTable({
                    dom: 'Bfrtip',
                    language: {
                        'emptyTable': '...'
                    },
                    buttons: {
                        dom: {
                            button: {
                                className: 'btn btn-default'
                            }
                        },
                        buttons: [
                            'excelHtml5',
                            'pdfHtml5'
                        ]
                    }
                    ,
                    columnDefs: [{
                        targets: [columnNumber - 1]
                    }]
                });


                // Add placeholder to the datatable filter option
                jQuery('.dataTables_filter input[type=search]').attr('placeholder', 'Type to filter...');


                // Enable Select2 select for the length option
                jQuery('.dataTables_length select').select2({
                    minimumResultsForSearch: Infinity,
                    width: 'auto'
                });
            }
            , timout);


    }

    static convertDate(date: string) {
        console.log(date);
        if (date) {
            const HH = date.substring(11, 13);
            const mm = date.substring(14, 16);
            console.log(HH + ':' + mm);

            return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4) + (HH && mm ? ' ' + HH + ':' + mm : '');
        }
        return null;
    }

    static convertTime(time: string) {
        if (time) {
            return time.substring(0, 5);
        }
        return null;
    }

    static convertRealDate(date: string, time?: string) {
        if (date) {
            console.log(date);

            console.log(date.substring(0, 2), date.substring(3, 5), date.substring(6, 10));
            if (!time) {
                return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1), +date.substring(0, 2));
            } else {
                return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1),
                    +date.substring(0, 2), +time.substring(0, 2), +time.substring(3, 5));
            }
        }
        return null;
    }

    static getModalTemplate() {
        return '<div class="modal-dialog modal-lg" role="document">\n' +
            '  <div class="modal-content">\n' +
            '    <div class="modal-header">\n' +
            '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
            '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
            '    </div>\n' +
            '    <div class="modal-body">\n' +
            '      <div class="floating-buttons btn-group"></div>\n' +
            '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>\n';
    }

    static getPreviewZoomButtonClasses() {
        return {
            toggleheader: 'btn btn-default btn-icon btn-xs btn-header-toggle',
            fullscreen: 'btn btn-default btn-icon btn-xs',
            borderless: 'btn btn-default btn-icon btn-xs',
            close: 'btn btn-default btn-icon btn-xs'
        };
    }

    static getPreviewZoomButtonIcons() {
        return {
            prev: '<i class="icon-arrow-left32"></i>',
            next: '<i class="icon-arrow-right32"></i>',
            toggleheader: '<i class="icon-menu-open"></i>',
            fullscreen: '<i class="icon-screen-full"></i>',
            borderless: '<i class="icon-alignment-unalign"></i>',
            close: '<i class="icon-cross3"></i>'
        };
    }

    static modalTemplate() {
        return '<div class="modal-dialog modal-lg" role="document">\n' +
            '  <div class="modal-content">\n' +
            '    <div class="modal-header">\n' +
            '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
            '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
            '    </div>\n' +
            '    <div class="modal-body">\n' +
            '      <div class="floating-buttons btn-group"></div>\n' +
            '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>\n';
    }

    static getFileActionSettings() {
        return {
            removeIcon: '<i class="icon-bin"></i>',
            removeClass: 'btn btn-link btn-xs btn-icon',
            uploadIcon: '<i class="icon-upload"></i>',
            uploadClass: 'btn btn-link btn-xs btn-icon',
            zoomIcon: '<i class="icon-zoomin3"></i>',
            zoomClass: 'btn btn-link btn-xs btn-icon',
            downloadIcon: '<i class="icon-download"></i>',
            downloadClass: 'btn btn-link btn-xs btn-icon',
            indicatorNew: '<i class="icon-file-plus text-slate"></i>',
            indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
            indicatorError: '<i class="icon-cross2 text-danger"></i>',
            indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
        };
    }

    static initializeBasicUploadFile(url: string, token: string, className: string, allowedFileExtensions?: string[]) {


        jQuery('.' + className).fileinput({
            uploadUrl: url, // server upload action
            uploadAsync: true,
            browseLabel: 'Browse',
            showPreview: true,
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>',
                modal: Utils.modalTemplate()
            },
            allowedFileExtensions: allowedFileExtensions,
            initialCaption: 'No file selected',
            previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
            previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
            fileActionSettings: Utils.getFileActionSettings(),
            ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}}
        });
    }

    static initializeUploadFile(url: string, token: string, className: string,
                                showRemove: boolean,
                                showUpload: boolean,
                                maxFileCount?: number,
                                initialData?: any[],
                                initialPreviewConfig?: InitialPreviewConfig[]) {
        jQuery(className).fileinput({
            uploadUrl: url, // server upload action
            uploadAsync: true,
            showRemove: showRemove,
            showUpload: showUpload,
            maxFileCount: maxFileCount,
            overwriteInitial: false,
            initialPreview: initialData,
            initialPreviewAsData: true,
            initialPreviewFileType: 'image', // image is the default and can be overridden in config below
            initialPreviewConfig: initialPreviewConfig,
            fileActionSettings: Utils.getFileActionSettings(),
            purifyHtml: true, // this by default purifies HTML data for preview
            initialCaption: 'Pas encore de fichier selectionné',
            previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
            previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
            ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
        });
    }

    static convertDateServer(date: string) {
        const HH = date.substring(11, 13);
        const mm = date.substring(14, 16);
        return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2) + (HH && mm ? ' ' + HH + ':' + mm : '');
    }

    static loadTypeFromExtension(ext: string) {
        if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
            return 'video';
        }
        if (ext.toLowerCase().match(/(pdf)$/i)) {
            return 'pdf';
        }
    }

    static loadFileTypeFromExtension(ext: string) {
        if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
            return 'video/' + ext;
        }
        if (ext.toLowerCase().match(/(pdf)$/i)) {
            return 'pdf';
        }
    }

    static getYears(minYear: number) {
        const years: number[] = [];

        for (let i = minYear; i <= new Date().getFullYear(); i++) {
            years.push(i);
        }
        return years;
    }

    static getUniversityYears(minYear: number) {
        const uniYears: string[] = [];

        for (let i = minYear; i <= new Date().getFullYear(); i++) {
            let j = i + 1;
            uniYears.push(i + '-' + j);
        }

        return uniYears;
    }

    static verifyNewStudent(study_access_year: string) {
        if (!study_access_year) {
            return false;
        }
        const year = parseInt(study_access_year.substring(0, 4));
        return year === new Date().getFullYear();
    }

    static getStatusSection(validations: any[], id_section: number) {

        if (!validations) {
            return null;
        }
        const result = jQuery.grep(validations, function (e) {
            return e.id_section === id_section;
        });


        if (!result || result.length === 0) {
            return null;
        } else {
            return result[0];
        }
    }


    static initSelect(className: string, isSearch?: boolean, withTags = false) {
        const select = jQuery('.' + className);
        if (isSearch === false) {
            select.select2({
                minimumResultsForSearch: Infinity,
                tags: withTags,
            });
        } else {
            select.select2({
                tags: withTags
            });
        }

    }

    static setValuesjQueryCmp(className: string, values: any, timout: number) {

        if (values) {
            setTimeout(function () {
                const component = jQuery('.' + className);
                component.val(values).trigger('change');
            }, timout);

        }
    }

    static getCurrentUniversityYear(cycle: number) {
        // cycle => 1 : PDCEM , 2 : IRESIDANT
        let fullYear = new Date().getFullYear();
        if (cycle === 1) {
            if (new Date().getMonth() >= 0 && new Date().getMonth() <= 5) {
                fullYear -= 1;
            }
        } else {
            if (new Date().getMonth() >= 0 && new Date().getMonth() <= 8) {
                fullYear -= 1;
            }
        }
        return (fullYear) + '-' + (fullYear + 1);
    }

    static getLabelNiveau(niveau_id: number) {
        switch (niveau_id) {
            case 1 :
                return 'PCEM1';
            case 2 :
                return 'PCEM2';
            case 3 :
                return 'DCEM1';
            case 4 :
                return 'DCEM2';
            case 5 :
                return 'DCEM3';
            case 6 :
                return 'DCEM4';
            case 7 :
                return 'DCEM4 (AR)';
            case 8:
                return 'TCEM1';
            case 9:
                return 'TCEM2';
            case 10:
                return 'TCEM3';
            case 11:
                return 'TCEM4';
            case 12:
                return 'TCEM5';

        }
    }


    static getLevelIdFromName(niveau_name: any) {
        switch (niveau_name) {
            case 'PCEM1' :
                return 1;
            case 'PCEM2' :
                return 2;
            case 'DCEM1' :
                return 3;
            case 'DCEM2' :
                return 4;
            case 'DCEM3' :
                return 5;
            case 'DCEM4' :
                return 6;
            case 'DCEM4(AR)' :
                return 7;
            case 'TCEM1' :
                return 8;
            case 'TCEM2' :
                return 9;
            case 'TCEM3' :
                return 10;
            case 'TCEM4' :
                return 11;
            case 'TCEM5' :
                return 12;
        }
    }

    static getResultIdFromName(result_name: any) {
        switch (result_name) {
            case 'Redoublant' :
                return 1;
            case 'Admis' :
                return 2;
            case 'Admis avec crédit' :
                return 3;
            case 'admis et orienté' :
                return 5;
        }
    }

    static getResultNameFromId(result_id: number) {
        switch (result_id) {
            case 1 :
                return 'Redoublant';
            case  2:
                return 'Admis';
            case 3:
                return 'Admis avec crédit';
            case 5 :
                return 'admis et orienté';
        }
    }


    static setValueToRadioBox(className: string, value: any, timout: number) {
        const radioBox = jQuery('.' + className).prop('checked', false);
        jQuery.uniform.update(radioBox);
        if (value !== undefined) {
            setTimeout(function () {
                const radioBox = jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
                jQuery.uniform.update(radioBox);
            }, timout);
        }
    }

    static initDatePicker(className: string, timePicker: boolean) {
        jQuery('.' + className).daterangepicker({
            timePicker: timePicker,
            timePicker24Hour: true,
            singleDatePicker: true,
            showDropdowns: true,
            locale: {
                format: 'DD/MM/YYYY HH:mm'
            }
        });

    }

    static getRandomString() {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    static getRandomStringWithNumbers(length: number) {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    static getIdentifiantStudent(student: any) {
        if (!student.cin && !student.passport) {
            return '-';
        }
        return student.cin ? student.cin.code : student.passport.code;
    }

    static initSliderMenu(direction: string, className: string, timout: number) {
        setTimeout(function () {
            const oppositeDirection = direction === 'right' ? 'left' : 'right';
            jQuery('#' + className).slideReveal({
                position: direction,
                trigger: jQuery('#' + className + ' .handle'),
                push: false,
                shown: function (obj) {
                    obj.find('.handle').html('<span class="glyphicon glyphicon-chevron-' + direction + '"></span>');
                },
                hidden: function (obj) {
                    obj.find('.handle').html('<span class="glyphicon glyphicon-chevron-' + oppositeDirection + '"></span>');
                }
            });
        }, timout);
    }


    static getRandomInRange(from, to, fixed) {
        return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
        // .toFixed() returns string, so ' * 1' is a trick to convert to number
    }

    static initCheckBox(className: string) {
        jQuery('.' + className).uniform({radioClass: 'choice'});
    }


    static getAllRoles() {
        return ['ROLE_CLIENT', 'ROLE_SUPER_ADMIN'];
    }

    public static hexToRgb(hex) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        return [b, g, r];
    }

    static colorCodeToRGB(colorCode: string, opacity: number) {
        const codeWithoutD = colorCode.substring(1);
        const rgbArray = Utils.hexToRgb(codeWithoutD);
        return 'rgb(' + rgbArray[2] + ',' + rgbArray[1] + ',' + rgbArray[0] + ',' + opacity + ')';
    }

    static convertToBigValue(n, dr) {
        if (n < 1000) {
            return n + '';
        }
        let x = ('' + n).length, p = Math.pow, d = p(10, dr);
        x -= x % 3;
        return Math.round(n * d / p(10, x)) / d + ' kMGTPE'[x / 3];
    }

    static showErrorNoty(message = 'Erreur', timeout_ = 3000) {
        // @ts-ignore
        new Noty({
            theme: 'metroui',
            type: 'error',
            layout: 'topRight',
            timeout: timeout_,
            progressBar: true,
            text: message
        }).show();
    }

    static showSuccessNoty(message = 'Succès', timeout_ = 3000) {
        // @ts-ignore
        new Noty({
            theme: 'metroui',
            type: 'success',
            layout: 'topRight',
            timeout: timeout_,
            progressBar: true,
            text: message
        }).show();
    }
}


export class InitialPreviewConfig {
    caption?: string;
    size?: number;
    width?: string;
    type?: string;
    filetype?: string;
    url: string;
    key: number;
    downloadUrl?: string;
}

