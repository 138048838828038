import {Discipline} from './discipline';
import {Matiere} from './matiere';
import {UniteSessionDisciplineMatiere} from './UniteSessionDisciplineMatiere';
import {UniteSession} from './UniteSession';
import {MoyenneDiscipline} from './MoyenneDiscipline';

export class MoyenneUnite {
    id: string;
    moyenne: number;
    uniteSession: UniteSession;
    moyennesDisciplines: MoyenneDiscipline[];
}
