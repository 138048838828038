import {Component, OnInit} from '@angular/core';
import {Discipline} from '../../shared/models/discipline';
import {Matiere} from '../../shared/models/matiere';
import {MatiereService} from '../../shared/services/matiere.service';
import {DisciplineService} from '../../shared/services/discipline.service';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import Noty from 'noty';

declare let jQuery: any;

@Component({
    selector: 'app-add-discipline',
    templateUrl: './add-discipline.component.html',
    styleUrls: ['./add-discipline.component.css']
})
export class AddDisciplineComponent implements OnInit {
    discipline: Discipline = new Discipline();
    matieres: Matiere[] = [];
    selection: SelectTest = new SelectTest();
    isEditMode = false;
    isSubmitted = false;

    constructor(private matiereService: MatiereService,
                private disciplineService: DisciplineService,
                private route: ActivatedRoute,
                private router: Router,
    ) {
    }

    ngOnInit() {

        this.getMatieres();


    }


    getMatieres() {
        this.enableLoader();
        this.matiereService.getAllMatieres().subscribe(
            (data: Matiere[]) => {
                this.matieres = data;
                this
                    .route
                    .params
                    .pipe(
                        map(p => p['id']),
                        switchMap(id => {
                            if (id === 'new') {
                                this.disableLoader();
                                return of(new Discipline());
                            }
                            this.isEditMode = true;
                            return this.disciplineService.findById(id);
                        })
                    )
                    .subscribe(
                        (discipline: Discipline) => {
                            this.discipline = discipline;
                            if (discipline.matieres) {
                                this.selection.multipleSelect = discipline.matieres.map((mat) => mat.id.toString());
                                console.log(this.selection.multipleSelect);
                            }
                            this.disableLoader();
                        }
                    );
            },
            (error) => {
                this.disableLoader();
            }
        );
    }


    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }

    onResetClick() {
        this.discipline = new Discipline();
    }


    saveData() {
        this.isSubmitted = true;

        var matieresToSave: Matiere[] = [];
        console.log(this.selection.multipleSelect);
        for (let matiere of this.matieres) {
            for (let id of this.selection.multipleSelect) {
                if (id == matiere.id) {
                    matieresToSave.push(matiere);
                }
            }
        }
        this.discipline.matieres = matieresToSave;
        if (!this.canSubmit()) {
            new Noty({
                theme: 'metroui',
                type: 'error',
                layout: 'topRight',
                timeout: 5000,
                progressBar: true,
                text: 'Erreur : Vérifier que tous les champs sont remplis !'
            }).show();
            return;
        }

        if (!this.isEditMode) {
            this.addDiscipline();
        } else {
            this.editDiscipline();
        }

    }

    editDiscipline() {
        this.disciplineService.editDiscipline(this.discipline).subscribe(
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'success',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Le discipline a été bien enregistré !'
                }).show();
                this.router.navigate(['/discipline/list']);
            },
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Erreur : Le discipline n\'a pas été  enregistré!'
                }).show();

            }
        );
    }

    addDiscipline() {
        this.disciplineService.addDiscipline(this.discipline).subscribe(
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'success',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Le discipline a été bien enregistré !'
                }).show();
                this.router.navigate(['/discipline/list']);
            },
            () => {
                new Noty({
                    theme: 'metroui',
                    type: 'error',
                    layout: 'topRight',
                    timeout: 5000,
                    progressBar: true,
                    text: 'Erreur : Le discipline n\'a pas été  enregistré!'
                }).show();

            }
        );
    }

    canSubmit(): boolean {
        return !(!this.discipline.titre || this.discipline.titre == '' || this.selection.multipleSelect.length == 0);
    }

}

export class SelectTest {
    normalSelect: number;
    multipleSelect: string[];
}
