/**
 * Created by Abbes on 15/06/2017.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../user.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.getCurrentClient()
            .pipe(
                map(e => {
                    console.log(e);
                    if (e) {
                        return true;
                    }
                }),
                catchError((err) => {
                    this.userService.clear();
                    this.router.navigate(['/auth/login']);
                    return of(false);
                })
            );
    }
}
