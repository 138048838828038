import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListUniteComponent} from './list-unite/list-unite.component';
import {AddUniteComponent} from './add-unite/add-unite.component';

const routes: Routes = [
    {
        path: 'list',
        component: ListUniteComponent
    },
    {
        path: 'add',
        component: AddUniteComponent
    },
    {
        path: 'edit/:uniteId',
        component: AddUniteComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class UniteRoutingModule {
}
