import {SessionPlanning} from './SessionPlanning';
import {Niveau} from '../niveau';
import {ConfigCase} from './ConfigCase';
import {OrdreUnite} from './OrdreUnite';

export class ConfigPlanning {
    id: string;
    niveau : Niveau;
    session_planning : SessionPlanning;
    configs_cases : ConfigCase[];
    ordres_unites : OrdreUnite[];
}
