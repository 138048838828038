import {Utils} from '../../utils/utils';

export class ChartData {

    idName: string;
    data: number;
    height: number;
    animate: boolean;
    easing: string;
    duration: number;
    delay: number;
    color: string; // Color Code Format
    tooltip: string;

    // '#members-online', 24, 50, true, 'elastic', 1200, 50, 'rgba(255,255,255,0.5)', 'members');
    static getDefautChartData(color: string, tooltip: string, data) {
        return {
            idName: '',
            data: data,
            height: 50,
            animate: true,
            easing: 'elastic',
            duration: 1200,
            delay: 50,
            color: Utils.colorCodeToRGB(color, 0.5),
            tooltip: tooltip
        };
    }
}
