import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConfigPlanning} from '../models/planning/ConfigPlanning';


@Injectable()
export class SessionPlanningService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }
    getAllSessionsPlannings(){
        const url = Config.baseUrlApi + '/sessionsPlannings';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));

    }

}
