import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ChartUtils} from '../../utils/chart-utils';
import {ChartData} from '../../models/ui/chart-data';
import {Utils} from '../../utils/utils';

@Component({
    selector: 'app-widget-chart',
    templateUrl: './widget-chart.component.html',
    styleUrls: ['./widget-chart.component.css']
})
export class WidgetChartComponent implements OnInit, AfterViewInit {

    @Input()
    chart: ChartData;

    @Input()
    options: WidgetOption;

    bigValue: string;


    constructor() {
    }

    ngOnInit() {
        this.chart.idName = Utils.getRandomStringWithNumbers(10);
        this.bigValue = Utils.convertToBigValue(this.options.value, 3);
    }

    ngAfterViewInit(): void {
        //rgba(85,216,254,0.5)
        //elastic
        const baseContext = this;
        ChartUtils.initBarChart('#' + baseContext.chart.idName,
            baseContext.chart.data,
            baseContext.chart.height,
            baseContext.chart.animate,
            baseContext.chart.easing,
            baseContext.chart.duration,
            baseContext.chart.delay,
            baseContext.chart.color,
            baseContext.chart.tooltip);

    }

}

export class WidgetOption {
    title: string;
    value: number;
    percentage: number;
    positive: boolean;

    static getDefautWidget(title: string, value: number, percentage: number, positive: boolean) {
        return {
            title: title,
            value: value,
            percentage: percentage,
            positive: positive
        };
    }
}
