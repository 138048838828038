import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user/user';
import {Config} from '../config';
import {StorageService} from './storage.service';

@Injectable()
export class ClientService extends GenericService {
    loggedAdmin: User;

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
        this.loggedAdmin = <User>storageService.read(Config.userKey);
    }

}
