import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../shared/services/storage.service';
import {UserService} from '../shared/services/user.service';
import {Client} from '../shared/models/client';
import {LoadPageService} from '../shared/services/load-page.service';
import {NavigationMain} from '../shared/models/menu/navigation-main';
import {DOCUMENT} from '@angular/common';

declare let jQuery: any;

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit, AfterViewInit {

    user: Client;

    title: string;

    components: NavigationMain[] = [];

    constructor(private storageService: StorageService,
                public router: Router, private userService: UserService,
                private route: ActivatedRoute,
                private loadPageService: LoadPageService,
                @Inject(DOCUMENT) private document: Document) {

        this.user = this.userService.loggedClient;

        if (!this.user) {
            this.user = new Client();
        }
        /*this.userService
            .getCurrentClient()
            .subscribe(
                (data: Client) => {
                    this.userService.storeUser(data);
                    this.user = data;
                },
                (error) => {

                }
            );*/

        /*this.route.queryParams.subscribe(
            params => {
                console.log(params.reload);
                if (params.reload) {
                    window.location.href = '/';
                }
            }
        );*/
    }

    ngOnInit() {


        this.components = [
            {
                name: 'Dashboard',
                icon: 'icon-stats-bars2',
                url: '/',
                title: 'Overview'
            },
            {
                name: 'Sessions Des Examens',
                icon: 'icon-graduation2',
                childrens: [
                    {
                        name: 'Ajouter une Session',
                        url: '/session/add'
                    },
                    {
                        name: 'Liste des Sessions',
                        url: '/session/list'
                    }
                ]
            },
            {
                name: 'Gestion des matières',
                icon: 'icon-lab',
                childrens: [
                    {
                        name: 'Ajouter une matière',
                        url: '/matiere/add'
                    },
                    {
                        name: 'Liste des matières',
                        url: '/matiere/list'
                    }
                ]
            }
            ,
            {
                name: 'Gestion des disciplines',
                icon: 'icon-graduation2',
                childrens: [
                    {
                        name: 'Ajouter une discipline',
                        url: '/discipline/edit/new'
                    },
                    {
                        name: 'Liste les disciplines',
                        url: '/discipline/list'
                    }
                ]
            }, {
                name: 'Gestion des unités',
                icon: 'icon-graduation2',
                childrens: [
                    {
                        name: 'Ajouter une unité',
                        url: '/unite/add'
                    },
                    {
                        name: 'Liste des unités',
                        url: '/unite/list'
                    }
                ]
            }, {
                name: 'Configuration des Plannings',
                icon: 'icon-graduation2',
                childrens: [
                    {
                        name: 'Ajouter une Configuration',
                        url: '/config-planning/add'
                    },
                    {
                        name: 'Liste des Configurations',
                        url: '/config-planning/list'
                    }
                ]
            }
        ];

        this.changeActiveUrl(this.router.url);


        this.loadPageService.initBeforeLoad();
    }

    ngAfterViewInit(): void {
        this.loadPageService.initAfterLoad();
    }

    changeActiveUrl(url: string) {
        this.components.forEach(
            component => {
                component.active = '';
                if (url === component.url) {
                    component.active = 'active';
                    this.title = component.title;
                }
                if (component.childrens) {
                    component.childrens.forEach(
                        child => {
                            child.active = '';
                            if (url.indexOf(child.url) !== -1) {
                                child.active = 'active';
                            }
                        }
                    );
                }
            }
        );
    }

    goUrl(url: string) {
        if (url) {
            this.router.navigate([url]);
            this.changeActiveUrl(url);
        }
    }

    logout() {
        this.storageService.removeAll();
        this.router.navigateByUrl('/auth/login');
    }

}



