import {SessionPlanning} from './SessionPlanning';
import {Niveau} from '../niveau';
import {PeriodeSeance} from './PeriodeSeance';
import {ConfigPlanning} from './ConfigPlanning';
import {Unite} from '../unite';

export class ConfigCase {
    id: string;
    num_day: number;
    created_Date:Date;
    periode_seance:PeriodeSeance;
    config_planning: ConfigPlanning;
    unites: Unite[];
    unites_ids: string[];
}
