import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListDisciplineComponent} from './list-discipline/list-discipline.component';
import {AddDisciplineComponent} from './add-discipline/add-discipline.component';

const routes: Routes = [
    {
        path: 'list',
        component: ListDisciplineComponent
    },
    {
        path: 'edit/:id',
        component: AddDisciplineComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class DisciplineRoutingModule {
}
