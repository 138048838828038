import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AddConfigPlanningComponent} from './add-config-planning/add-config-planning.component';
import {ListConfigPlanningComponent} from './list-config-planning/list-config-planning.component';

const routes: Routes = [

    {
        path: 'add',
        component: AddConfigPlanningComponent
    },
    {
        path: 'list',
        component: ListConfigPlanningComponent
    },{
        path: 'edit/:config_planning_id',
        component: AddConfigPlanningComponent
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConfigPlanningRoutingModule {
}
