import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './error/not-found/not-found.component';
import {CanActivateViaAuthGuard} from './shared/services/guards/auth-guard.service';
import {FullLayoutComponent} from './layouts/full-layout.component';
import {AuthPageComponent} from './manage-user/auth-page/auth-page.component';
import {NotAuthorizedUserGuard} from './shared/services/guards/not-authorized-user-guard';
import {ManageUserModule} from './manage-user/manage-user.module';
import {CrudExampleModule} from './crud-example/crud-example.module';
import {MatiereModule} from './matiere/matiere.module';
import {DisciplineModule} from './discipline/discipline.module';
import {UniteModule} from './unite/unite.module';
import {SessionModule} from './session/session.module';
import {ConfigPlanningModule} from './config-planning/config-planning.module';

export function loadManageUserModule() {
    return ManageUserModule;
}

export function loadExampleCrudModule() {
    return CrudExampleModule;
}

export function loadMatiereModule() {
    return MatiereModule;
}

export function loadConfigPlanningModule() {
    return ConfigPlanningModule;
}

export function loadUniteModule() {
    return UniteModule;
}

export function loadDisciplineModule() {
    return DisciplineModule;

}
export function loadSessionModule() {
    return SessionModule;

}

export const routes: Routes = [
    {
        path: '',
        component: FullLayoutComponent,
        canActivate: [CanActivateViaAuthGuard],
        children: [
            {
                path: 'example',
                loadChildren: loadExampleCrudModule
            },
            {
                path: 'matiere',
                loadChildren: loadMatiereModule,
            },
            {
                path: 'discipline',
                loadChildren: loadDisciplineModule
            },
            {
                path: 'session',
                loadChildren: loadSessionModule
            },
            {
                path: 'unite',
                loadChildren: loadUniteModule
            },
            {
                path: 'config-planning',
                loadChildren: loadConfigPlanningModule
            },

        ],
    },
    {
        path: 'auth',
        loadChildren: loadManageUserModule,
        canActivate: [NotAuthorizedUserGuard]
    },
    {
        path: 'admin/auth',
        component: AuthPageComponent,
        canActivate: [NotAuthorizedUserGuard]
    },
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
