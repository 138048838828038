import {Component, OnInit} from '@angular/core';
import {DisciplineService} from '../../shared/services/discipline.service';
import {Discipline} from '../../shared/models/discipline';
import {Utils} from '../../shared/utils/utils';

declare let jQuery: any;
declare let swal : any;
@Component({
    selector: 'app-list-dicipline',
    templateUrl: './list-discipline.component.html',
    styleUrls: ['./list-discipline.component.css']
})
export class ListDisciplineComponent implements OnInit {

    disciplines: Discipline[];

    constructor(private disciplineService: DisciplineService) {
    }

    ngOnInit() {
        this.getAllDisciplines();
    }

    getAllDisciplines() {
        this.enableLoader();
        this.disciplineService.getAllDisciplines().subscribe(
            (data: any) => {
                this.disciplines = data;
                console.log(this.disciplines);
                Utils.initializeDataTables('datatable', 20, 4);
                setTimeout(this.disableLoader, 500);

            }
        );
    }

    onDelete(index: number) {
        let that = this;

                swal({
                        title: "Êtes vous sûrs?",
                        text: "Vous ne pouvez plus reverser la suppression!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-danger",
                        confirmButtonText: "Oui, Supprimer !",
                        closeOnConfirm: true
                    },
                    function(){
                        Utils.enableLoader();

                        that.disciplineService.deleteById(that.disciplines[index].id).subscribe(
                            (data) => {
                                that.disciplines=
                                    that.disciplines.filter(discipline=>discipline.id!=
                                        that.disciplines[index].id);
                                Utils.initializeDataTables('datatable', 100, 4);
                                Utils.disableLoader();
                                Utils.showSuccessNoty("La discipline a été supprimée");
                            },
                            (error) => {
                                Utils.disableLoader();
                                Utils.showErrorNoty("La discipline n'a pas été supprimée");
                            }
                        );
                        setTimeout(function(){Utils.disableLoader();},5000);
                    });
    }

    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }
}
