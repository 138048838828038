import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Discipline} from '../models/discipline';
import {Config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';


@Injectable()
export class DisciplineService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }


    addDiscipline(discipline: Discipline) {
        const url = Config.baseUrlApi + '/disciplines';

        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);

        return this.http.post<any>(url, discipline, {
            headers: headers
        })
            .pipe(catchError(this.handleErrors));
    }

    editDiscipline(discipline: Discipline) {
        const url = Config.baseUrlApi + '/disciplines';

        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);

        return this.http.put<any>(url, discipline, {
            headers: headers
        })
            .pipe(catchError(this.handleErrors));
    }


    getAllDisciplines() {
        const url = Config.baseUrlApi + '/disciplines';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));

    }

    findById(id: any) {
        const url = Config.baseUrlApi + '/disciplines/' + id;

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));
    }

    deleteById(id: any) {
        const url = Config.baseUrlApi + '/disciplines/' + id;

        return this.http.delete(url)
            .pipe(catchError(this.handleErrors));
    }


}
