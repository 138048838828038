import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConfigPlanning} from '../models/planning/ConfigPlanning';


@Injectable()
export class ConfigPlanningService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }
    getAllConfigsPlannings(){
        const url = Config.baseUrlApi + '/configsPlannings';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));

    }


    addConfigPlanning(configPlanning: ConfigPlanning) {
        const url = Config.baseUrlApi + '/configsPlannings';

        return this.http.post(url,configPlanning)
            .pipe(catchError(this.handleErrors));
    }


    getConfigPlanningById(config_planning_id: string):Observable<ConfigPlanning> {
        const url = Config.baseUrlApi + '/configsPlannings/'+config_planning_id;

        return this.http.get<ConfigPlanning>(url)
            .pipe(catchError(this.handleErrors));

    }

    deleteConfigPlanning(id: any) {
        const url = Config.baseUrlApi + '/configsPlannings/'+id;

        return this.http.delete<ConfigPlanning>(url)
            .pipe(catchError(this.handleErrors));
    }

}
