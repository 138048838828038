import {Matiere} from './matiere';
import {Discipline} from './discipline';
import {Niveau} from './niveau';
import {Enseignant} from './enseignant';

export class Unite {
    id: string;
    code: string;
    nom: string;
    uniteDiscipline: UniteDiscipline[];
    niveau: Niveau;
    uniteType: UniteType;
    chef_unite:Enseignant;

}

export class UniteDisciplineMatiere {
    id: string;
    uniteDiscipline: UniteDiscipline;
    matiere:Matiere;
}

export class UniteDiscipline {
    id: string;
    discipline: Discipline;
    unite: Unite;
    unitesDisciplinesMatieres: UniteDisciplineMatiere[];
}

export class UniteType {
    id: string;
    label: string;
}

export class SpecificationChefUniteRequest{
    unite_id:string;
    chef_unite_id:string;
}
