import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MoyenneSession} from '../models/MoyenneSession';
import {Session} from '../models/session';


@Injectable()
export class SessionService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }
    getAllSessions(){
        const url = Config.baseUrlApi + '/sessions';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));

    }


    addSession(session: Session) {
        const url = Config.baseUrlApi + '/sessions';

        return this.http.post(url,session)
            .pipe(catchError(this.handleErrors));
    }

    uploadNotes(moyennes_session: MoyenneSession[]){
        const url = Config.baseUrlApi + '/sessions/uploadNotes';

        return this.http.post(url,moyennes_session)
            .pipe(catchError(this.handleErrors));

    }

    calculerMoyennes(session: Session) {
        const url = Config.baseUrlApi + '/sessions/calculateMoyennes';
        return this.http.put(url,session)
            .pipe(catchError(this.handleErrors));
    }

    getSessionExamenById(session_id: string):Observable<Session> {
        const url = Config.baseUrlApi + '/sessions/'+session_id;

        return this.http.get<Session>(url)
            .pipe(catchError(this.handleErrors));

    }

    deleteSession(id: any) {
        const url = Config.baseUrlApi + '/sessions/'+id;

        return this.http.delete<Session>(url)
            .pipe(catchError(this.handleErrors));
    }

    getAllMoyennesSessionsBySession(id: string):Observable<MoyenneSession[]> {
        const url = Config.baseUrlApi + '/moyennesSessions/bySession/'+id;

        return this.http.get<MoyenneSession[]>(url).pipe(catchError(this.handleErrors));

    }
}
