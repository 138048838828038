export class Client {
    id: string;
    username: string;
    is_enabled: boolean = true;
    is_credentials_non_expired: boolean = true;
    is_account_non_locked: boolean = true;
    is_account_non_expired: boolean = true;
    authorities: string;
    tenant: string;

    password: boolean;
    cpassword: boolean;
}
