import {Component, OnInit} from '@angular/core';
import {MatiereService} from '../../shared/services/matiere.service';
import {Matiere} from '../../shared/models/matiere';
import {Utils} from '../../shared/utils/utils';

declare let jQuery: any;
declare let swal : any;
@Component({
    selector: 'app-list-matiere',
    templateUrl: './list-matiere.component.html',
    styleUrls: ['./list-matiere.component.css']
})
export class ListMatiereComponent implements OnInit {

    matieres: Matiere[];

    constructor(private matiereService: MatiereService) {
    }

    ngOnInit() {
        this.getAllMatieres();
    }

    getAllMatieres() {
        this.enableLoader();
        this.matiereService.getAllMatieres().subscribe(
            (data: any) => {
                this.matieres = data;
                console.log(this.matieres);
                Utils.initializeDataTables('datatable', 20, 4);
                setTimeout(this.disableLoader, 500);

            }
        );
    }

    onDeleteMatiere(index: string) {
        let that = this;
        swal({
                title: "Êtes vous sûrs?",
                text: "Vous ne pouvez plus reverser la suppression!",
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Oui, Supprimer !",
                closeOnConfirm: true
            },
            function(){
                Utils.enableLoader();

                that.matiereService.deleteMatiere(that.matieres[index].id).subscribe(
                    () => {
                        that.matieres=that.matieres.filter(matiere=>matiere.id!=that.matieres[index].id);
                        Utils.initializeDataTables('datatable', 100, 4);
                        Utils.disableLoader();
                        Utils.showSuccessNoty("La matière a été supprimée");
                    },
                    () => {
                        Utils.disableLoader();
                        Utils.showErrorNoty("La matière n'a pas été supprimée");
                    }
                );
                setTimeout(function(){Utils.disableLoader();},5000);
            });
    }

    enableLoader() {
        const loader = jQuery('#mainCard');

        loader.block({
            message: '<i class="icon-spinner spinner"></i>',
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: 0.8,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });
    }

    disableLoader() {
        const loader = jQuery('#mainCard');

        loader.unblock();
    }
}

