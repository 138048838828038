import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage.service';
import {Config} from '../config';
import {catchError} from 'rxjs/operators';
import {Matiere} from '../models/matiere';
import {SpecificationChefUniteRequest, Unite} from '../models/unite';

@Injectable()
export class UniteService extends GenericService {

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
    }


    getAllUnites() {
        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);
        const url = Config.baseUrlApi + '/unites';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));
    }


    specifyChefUnite(spec: SpecificationChefUniteRequest) {
        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);
        const url = Config.baseUrlApi + '/unites/specifyChef';

        return this.http.put<any>(url, spec, {
            headers: headers
        })
            .pipe(catchError(this.handleErrors));
    }

    getAllUnitesTypes() {
        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);
        const url = Config.baseUrlApi + '/uniteType';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));
    }

    addUnite(unite: Unite) {
        const url = Config.baseUrlApi + '/unites';

        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);

        return this.http.post<any>(url, unite, {
            headers: headers
        })
            .pipe(catchError(this.handleErrors));

    }

    deleteUnite(id: string) {
        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);
        const url = Config.baseUrlApi + '/unites/' + id;

        return this.http.delete(url)
            .pipe(catchError(this.handleErrors));
    }

    getUniteById(id: string) {
        let headers = new HttpHeaders();

        headers = headers.append('Authorization', 'Bearer ' + Config.userTokenKey);
        const url = Config.baseUrlApi + '/unites/' + id;

        return this.http.get<any>(url)
            .pipe(catchError(this.handleErrors));
    }
}
