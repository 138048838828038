export class Enseignant {
    id: string;
    nom: string;
    prenom: string;
    cin: string;
}

export class EnseignantRequest {
    id: string;
}
