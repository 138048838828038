import {NgModule} from '@angular/core';
import {MatButtonModule, MatCheckboxModule, MatInputModule, MatProgressSpinnerModule} from '@angular/material';
import {MatProgressButtonsModule} from 'mat-progress-buttons';

@NgModule({
    imports: [
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatProgressButtonsModule
    ],
    exports: [
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatProgressButtonsModule
    ],
    declarations: []
})
export class MaterialsModule {

}
