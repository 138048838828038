import {Discipline} from './discipline';
import {Matiere} from './matiere';
import {UniteSessionDisciplineMatiere} from './UniteSessionDisciplineMatiere';
import {UniteSessionDiscipline} from './UniteSessionDiscipline';
import {NoteMatiere} from './NoteMatiere';

export class MoyenneDiscipline {
    id: string;
    moyenne: number;
    uniteSessionDiscipline: UniteSessionDiscipline;
    notesMatieres: NoteMatiere[];
}
