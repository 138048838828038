import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../user.service';
import {Role} from '../../models/Role';

@Injectable()
export class CanActivateViaAuthSuperAdminAuth implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate() {
        // return true;
        if (this.userService.getAuthorities(Role.SUPER_ADMIN)) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }

}
