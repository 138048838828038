import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddMatiereComponent} from './add-matiere/add-matiere.component';
import {ListMatiereComponent} from './list-matiere/list-matiere.component';
import {MatiereRoutingModule} from './matiere-routing.module';
import {FormsModule} from '@angular/forms';
import {MatiereService} from '../shared/services/matiere.service';
import {NgSelect2Component} from '../shared/components/ng-select2/ng-select2.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [AddMatiereComponent, ListMatiereComponent,
    ],
    imports: [
        CommonModule,
        MatiereRoutingModule,
        FormsModule,
        SharedModule
    ]
})
export class MatiereModule {
}
