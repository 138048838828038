import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../shared/services/user.service';
import {Subscription} from 'rxjs';
import {Credentials} from '../../shared/models/user/credentials';
import {AuthService} from '../../shared/services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatProgressButtonOptions} from 'mat-progress-buttons';
import {CustomerService} from '../../shared/services/customer.service';
import {StorageService} from '../../shared/services/storage.service';

declare let jQuery: any;
declare let swal: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['login.component.less'],
})
export class LoginComponent implements OnInit {


    @Input()
    admin: boolean;

    credentials: Credentials;
    btnOpts: MatProgressButtonOptions;
    loginForm: FormGroup;

    ngOnInit() {
        this.loginForm = new FormGroup(
            {
                email: new FormControl('', [Validators.required, Validators.email]),
                password: new FormControl('', [Validators.required, Validators.minLength(6)])
            }
        );

        this.settingOptionsBtn();
    }

    constructor(private userService: UserService,
                private authService: AuthService,
                private router: Router,
                private customerService: CustomerService,
                private storageService: StorageService,
    ) {
        this.credentials = new Credentials();
    }

    loginSubmit() {
        console.log(this.credentials);

        this.customerService.login(this.credentials).subscribe(
            (data: any) => {
                this.userService.storeUser(data.user);
                this.userService.storeUserAccessToken(data.accessToken);
                this.customerService.loggedAdmin = data.user;
                this.router.navigate(['/']);
            },
            (error) => {
                console.log('erreur auth');
            }
        )
        ;


        // this.btnOpts.active = true;
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.loginForm.controls[controlName].hasError(errorName);
    };

    private settingOptionsBtn() {
        this.loginForm.valueChanges.subscribe(() => {
            if (this.loginForm.status === 'INVALID') {
                this.btnOpts.disabled = true;
            } else {
                this.btnOpts.disabled = false;
            }
        });
        this.btnOpts = {
            active: false,
            text: 'Login',
            buttonColor: 'primary',
            barColor: 'primary',
            raised: true,
            stroked: false,
            mode: 'indeterminate',
            disabled: false
        };
    }
}


