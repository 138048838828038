import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MoyenneSession} from '../models/MoyenneSession';
import {Session} from '../models/session';
import {Niveau} from '../models/niveau';
import {Etudiant} from '../models/Etudiant';


@Injectable()
export class EtudiantService extends GenericService {

    constructor(private http: HttpClient) {
        super();
    }


    getAllEtudiantsByNiveau(niveau: Niveau):Observable<Etudiant[]> {
        const url = Config.baseUrlApi + '/etudiants/byNiveau/'+niveau.id;
        return this.http.get<Etudiant[]>(url)
            .pipe(catchError(this.handleErrors));

    }
}
