import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListMatiereComponent} from './list-matiere/list-matiere.component';
import {AddMatiereComponent} from './add-matiere/add-matiere.component';
import {FormsModule} from '@angular/forms';

const routes: Routes = [
    {
        path: 'list',
        component: ListMatiereComponent
    },
    {
        path: 'add',
        component: AddMatiereComponent
    },
    {
        path: 'edit/:matiereId',
        component: AddMatiereComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)
        ],
    exports: [RouterModule]
})
export class MatiereRoutingModule {
}
