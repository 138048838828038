import {Component, OnInit} from '@angular/core';
import {UserService} from '../../shared/services/user.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {RegisterRootDTO} from '../../shared/models/user/register-root-dto';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatProgressButtonOptions} from 'mat-progress-buttons';

declare var swal: any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


    btnOpts: MatProgressButtonOptions;
    busy: Subscription;
    user: RegisterRootDTO;

    registerForm: FormGroup;

    password = new FormControl('', [Validators.required]);

    constructor(private userService: UserService,
                private authService: AuthService,
                private router: Router) {
        this.user = new RegisterRootDTO();
    }

    ngOnInit() {
        this.registerForm = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.email,
            ]),
            password: this.password,
            cpassword: new FormControl('', [
                Validators.required,
                Validators.minLength(6),
                this.passwordMatch()
            ]),
            username: new FormControl('', [
                Validators.required
            ]),
        });

        this.settingOptionsBtn();
    }

    register() {
        console.log(this.user);
        // this.btnOpts.active = true;
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.registerForm.controls[controlName].hasError(errorName);
    };

    private passwordMatch() {
        let that = this;
        return (c: FormControl) => {
            return (c.value === that.password.value) ? null : {'passwordMatch': {valid: false}};
        };
    }

    private settingOptionsBtn() {
        this.registerForm.valueChanges.subscribe(() => {
            if (this.registerForm.status === 'INVALID') {
                this.btnOpts.disabled = true;
            } else {
                this.btnOpts.disabled = false;
            }
        });
        this.btnOpts = {
            active: false,
            text: 'Register',
            buttonColor: 'primary',
            barColor: 'primary',
            raised: true,
            stroked: false,
            mode: 'indeterminate',
            disabled: false
        };
    }
}
