import {Injectable} from '@angular/core';

declare let jQuery: any;

@Injectable()
export class LoadPageService {
    constructor() {
    }

    initAfterLoad() {
        App.initAfterLoad();
        console.log('after load');
    }

    initBeforeLoad() {

        setTimeout(function () {
            App.initBeforeLoad();
            App.initCore();
        }, 15);

    }


}

// Setup module
// ------------------------------

var App = function () {


    //
    // Setup module components
    //

    // Transitions
    // -------------------------

    // Disable all transitions
    var _transitionsDisabled = function () {
        jQuery('body').addClass('no-transitions');
        console.log('diasble transitions');
    };

    // Enable all transitions
    var _transitionsEnabled = function () {
        jQuery('body').removeClass('no-transitions');
        console.log('enabled transitions');
    };


    // Sidebars
    // -------------------------

    //
    // On desktop
    //

    // Resize main sidebar
    var _sidebarMainResize = function () {

        // Flip 2nd level if menu overflows
        // bottom edge of browser window
        var revertBottomMenus = function () {
            jQuery('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function () {
                var totalHeight = 0,
                    jQuerythis = jQuery(this),
                    navSubmenuClass = 'nav-group-sub',
                    navSubmenuReversedClass = 'nav-item-submenu-reversed';

                totalHeight += jQuerythis.find('.' + navSubmenuClass).filter(':visible').outerHeight();
                if (jQuerythis.children('.' + navSubmenuClass).length) {
                    if ((jQuerythis.children('.' + navSubmenuClass).offset().top + jQuerythis.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
                        jQuerythis.addClass(navSubmenuReversedClass);
                    } else {
                        jQuerythis.removeClass(navSubmenuReversedClass);
                    }
                }
            });
        };

        // If sidebar is resized by default
        if (jQuery('body').hasClass('sidebar-xs')) {
            revertBottomMenus();
        }

        // Toggle min sidebar class
        jQuery('.sidebar-main-toggle').on('click', function (e) {
            e.preventDefault();

            jQuery('body').toggleClass('sidebar-xs').removeClass('sidebar-mobile-main');
            revertBottomMenus();
        });
    };

    // Toggle main sidebar
    var _sidebarMainToggle = function () {
        jQuery(document).on('click', '.sidebar-main-hide', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-main-hidden');
        });
    };

    // Toggle secondary sidebar
    var _sidebarSecondaryToggle = function () {
        jQuery(document).on('click', '.sidebar-secondary-toggle', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-secondary-hidden');
        });
    };


    // Show right, resize main
    var _sidebarRightMainToggle = function () {
        jQuery(document).on('click', '.sidebar-right-main-toggle', function (e) {
            e.preventDefault();

            // Right sidebar visibility
            jQuery('body').toggleClass('sidebar-right-visible');

            // If visible
            if (jQuery('body').hasClass('sidebar-right-visible')) {

                // Make main sidebar mini
                jQuery('body').addClass('sidebar-xs');

                // Hide children lists if they are opened, since sliding animation adds inline CSS
                jQuery('.sidebar-main .nav-sidebar').children('.nav-item').children('.nav-group-sub').css('display', '');
            } else {
                jQuery('body').removeClass('sidebar-xs');
            }
        });
    };

    // Show right, hide main
    var _sidebarRightMainHide = function () {
        jQuery(document).on('click', '.sidebar-right-main-hide', function (e) {
            e.preventDefault();

            // Opposite sidebar visibility
            jQuery('body').toggleClass('sidebar-right-visible');

            // If visible
            if (jQuery('body').hasClass('sidebar-right-visible')) {
                jQuery('body').addClass('sidebar-main-hidden');
            } else {
                jQuery('body').removeClass('sidebar-main-hidden');
            }
        });
    };

    // Toggle right sidebar
    var _sidebarRightToggle = function () {
        jQuery(document).on('click', '.sidebar-right-toggle', function (e) {
            e.preventDefault();

            jQuery('body').toggleClass('sidebar-right-visible');
        });
    };

    // Show right, hide secondary
    var _sidebarRightSecondaryToggle = function () {
        jQuery(document).on('click', '.sidebar-right-secondary-toggle', function (e) {
            e.preventDefault();

            // Opposite sidebar visibility
            jQuery('body').toggleClass('sidebar-right-visible');

            // If visible
            if (jQuery('body').hasClass('sidebar-right-visible')) {
                jQuery('body').addClass('sidebar-secondary-hidden');
            } else {
                jQuery('body').removeClass('sidebar-secondary-hidden');
            }
        });
    };


    // Toggle content sidebar
    var _sidebarComponentToggle = function () {
        jQuery(document).on('click', '.sidebar-component-toggle', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-component-hidden');
        });
    };


    //
    // On mobile
    //

    // Expand sidebar to full screen on mobile
    var _sidebarMobileFullscreen = function () {
        jQuery('.sidebar-mobile-expand').on('click', function (e) {
            e.preventDefault();
            var jQuerysidebar = jQuery(this).parents('.sidebar'),
                sidebarFullscreenClass = 'sidebar-fullscreen';

            if (!jQuerysidebar.hasClass(sidebarFullscreenClass)) {
                jQuerysidebar.addClass(sidebarFullscreenClass);
            } else {
                jQuerysidebar.removeClass(sidebarFullscreenClass);
            }
        });
    };

    // Toggle main sidebar on mobile
    var _sidebarMobileMainToggle = function () {
        jQuery('.sidebar-mobile-main-toggle').on('click', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-right');

            if (jQuery('.sidebar-main').hasClass('sidebar-fullscreen')) {
                jQuery('.sidebar-main').removeClass('sidebar-fullscreen');
            }
        });
    };

    // Toggle secondary sidebar on mobile
    var _sidebarMobileSecondaryToggle = function () {
        jQuery('.sidebar-mobile-secondary-toggle').on('click', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-mobile-secondary').removeClass('sidebar-mobile-main sidebar-mobile-right');

            // Fullscreen mode
            if (jQuery('.sidebar-secondary').hasClass('sidebar-fullscreen')) {
                jQuery('.sidebar-secondary').removeClass('sidebar-fullscreen');
            }
        });
    };

    // Toggle right sidebar on mobile
    var _sidebarMobileRightToggle = function () {
        jQuery('.sidebar-mobile-right-toggle').on('click', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-mobile-right').removeClass('sidebar-mobile-main sidebar-mobile-secondary');

            // Hide sidebar if in fullscreen mode on mobile
            if (jQuery('.sidebar-right').hasClass('sidebar-fullscreen')) {
                jQuery('.sidebar-right').removeClass('sidebar-fullscreen');
            }
        });
    };

    // Toggle component sidebar on mobile
    var _sidebarMobileComponentToggle = function () {
        jQuery('.sidebar-mobile-component-toggle').on('click', function (e) {
            e.preventDefault();
            jQuery('body').toggleClass('sidebar-mobile-component');
        });
    };


    // Navigations
    // -------------------------

    // Sidebar navigation
    var _navigationSidebar = function () {

        // Define default class names and options
        var navClass = 'nav-sidebar',
            navItemClass = 'nav-item',
            navItemOpenClass = 'nav-item-open',
            navLinkClass = 'nav-link',
            navSubmenuClass = 'nav-group-sub',
            navSlidingSpeed = 250;

        // Configure collapsible functionality
        jQuery('.' + navClass).each(function () {
            jQuery(this).find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).not('.disabled').on('click', function (e) {
                e.preventDefault();

                // Simplify stuff
                var jQuerytarget = jQuery(this),
                    jQuerynavSidebarMini = jQuery('.sidebar-xs').not('.sidebar-mobile-main').find('.sidebar-main .' + navClass).children('.' + navItemClass);

                // Collapsible
                if (jQuerytarget.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
                    jQuerytarget.parent('.' + navItemClass).not(jQuerynavSidebarMini).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
                } else {
                    jQuerytarget.parent('.' + navItemClass).not(jQuerynavSidebarMini).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
                }

                // Accordion
                if (jQuerytarget.parents('.' + navClass).data('nav-type') == 'accordion') {
                    jQuerytarget.parent('.' + navItemClass).not(jQuerynavSidebarMini).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
                }
            });
        });

        // Disable click in disabled navigation items
        jQuery(document).on('click', '.' + navClass + ' .disabled', function (e) {
            e.preventDefault();
        });

        // Scrollspy navigation
        jQuery('.nav-scrollspy').find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).off('click');
    };

    // Navbar navigation
    var _navigationNavbar = function () {

        // Prevent dropdown from closing on click
        jQuery(document).on('click', '.dropdown-content', function (e) {
            e.stopPropagation();
        });

        // Disabled links
        jQuery('.navbar-nav .disabled a, .nav-item-levels .disabled').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });

        // Show tabs inside dropdowns
        jQuery('.dropdown-content a[data-toggle="tab"]').on('click', function (e) {
            jQuery(this).tab('show');
        });
    };


    // Components
    // -------------------------

    // Tooltip
    var _componentTooltip = function () {

        // Initialize
        jQuery('[data-popup="tooltip"]').tooltip();

        // Demo tooltips, remove in production
        var demoTooltipSelector = '[data-popup="tooltip-demo"]';
        if (jQuery(demoTooltipSelector).is(':visible')) {
            jQuery(demoTooltipSelector).tooltip('show');
            setTimeout(function () {
                jQuery(demoTooltipSelector).tooltip('hide');
            }, 2000);
        }
    };

    // Popover
    var _componentPopover = function () {
        jQuery('[data-popup="popover"]').popover();
    };


    // Card actions
    // -------------------------

    // Reload card (uses BlockUI extension)
    var _cardActionReload = function () {
        jQuery('.card [data-action=reload]:not(.disabled)').on('click', function (e) {
            e.preventDefault();
            var jQuerytarget = jQuery(this),
                block = jQuerytarget.closest('.card');

            // Block card
            jQuery(block).block({
                message: '<i class="icon-spinner2 spinner"></i>',
                overlayCSS: {
                    backgroundColor: '#fff',
                    opacity: 0.8,
                    cursor: 'wait',
                    'box-shadow': '0 0 0 1px #ddd'
                },
                css: {
                    border: 0,
                    padding: 0,
                    backgroundColor: 'none'
                }
            });

            // For demo purposes
            window.setTimeout(function () {
                jQuery(block).unblock();
            }, 2000);
        });
    };

    // Collapse card
    var _cardActionCollapse = function () {
        var jQuerycardCollapsedClass = jQuery('.card-collapsed');

        // Hide if collapsed by default
        jQuerycardCollapsedClass.children('.card-header').nextAll().hide();

        // Rotate icon if collapsed by default
        jQuerycardCollapsedClass.find('[data-action=collapse]').addClass('rotate-180');

        // Collapse on click
        jQuery('.card [data-action=collapse]:not(.disabled)').on('click', function (e) {
            var jQuerytarget = jQuery(this),
                slidingSpeed = 150;

            e.preventDefault();
            jQuerytarget.parents('.card').toggleClass('card-collapsed');
            jQuerytarget.toggleClass('rotate-180');
            jQuerytarget.closest('.card').children('.card-header').nextAll().slideToggle(slidingSpeed);
        });
    };

    // Remove card
    var _cardActionRemove = function () {
        jQuery('.card [data-action=remove]').on('click', function (e) {
            e.preventDefault();
            var jQuerytarget = jQuery(this),
                slidingSpeed = 150;

            // If not disabled
            if (!jQuerytarget.hasClass('disabled')) {
                jQuerytarget.closest('.card').slideUp({
                    duration: slidingSpeed,
                    start: function () {
                        jQuerytarget.addClass('d-block');
                    },
                    complete: function () {
                        jQuerytarget.remove();
                    }
                });
            }
        });
    };

    // Card fullscreen mode
    var _cardActionFullscreen = function () {
        jQuery('.card [data-action=fullscreen]').on('click', function (e) {
            e.preventDefault();

            // Define vars
            var jQuerytarget = jQuery(this),
                cardFullscreen = jQuerytarget.closest('.card'),
                overflowHiddenClass = 'overflow-hidden',
                collapsedClass = 'collapsed-in-fullscreen',
                fullscreenAttr = 'data-fullscreen';

            // Toggle classes on card
            cardFullscreen.toggleClass('fixed-top h-100 rounded-0');

            // Configure
            if (!cardFullscreen.hasClass('fixed-top')) {
                jQuerytarget.removeAttr(fullscreenAttr);
                cardFullscreen.children('.' + collapsedClass).removeClass('show');
                jQuery('body').removeClass(overflowHiddenClass);
                jQuerytarget.siblings('[data-action=move], [data-action=remove], [data-action=collapse]').removeClass('d-none');
            } else {
                jQuerytarget.attr(fullscreenAttr, 'active');
                cardFullscreen.removeAttr('style').children('.collapse:not(.show)').addClass('show ' + collapsedClass);
                jQuery('body').addClass(overflowHiddenClass);
                jQuerytarget.siblings('[data-action=move], [data-action=remove], [data-action=collapse]').addClass('d-none');
            }
        });
    };


    // Misc
    // -------------------------

    // Dropdown submenus. Trigger on click
    var _dropdownSubmenu = function () {

        // All parent levels require .dropdown-toggle class
        jQuery('.dropdown-menu').find('.dropdown-submenu').not('.disabled').find('.dropdown-toggle').on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();

            // Remove "show" class in all siblings
            jQuery(this).parent().siblings().removeClass('show').find('.show').removeClass('show');

            // Toggle submenu
            jQuery(this).parent().toggleClass('show').children('.dropdown-menu').toggleClass('show');

            // Hide all levels when parent dropdown is closed
            jQuery(this).parents('.show').on('hidden.bs.dropdown', function (e) {
                jQuery('.dropdown-submenu .show, .dropdown-submenu.show').removeClass('show');
            });
        });
    };

    // Header elements toggler
    var _headerElements = function () {

        // Toggle visible state of header elements
        jQuery('.header-elements-toggle').on('click', function (e) {
            e.preventDefault();
            jQuery(this).parents('[class*=header-elements-]').find('.header-elements').toggleClass('d-none');
        });

        // Toggle visible state of footer elements
        jQuery('.footer-elements-toggle').on('click', function (e) {
            e.preventDefault();
            jQuery(this).parents('.card-footer').find('.footer-elements').toggleClass('d-none');
        });
    };


    //
    // Return objects assigned to module
    //

    return {

        // Disable transitions before page is fully loaded
        initBeforeLoad: function () {
            _transitionsDisabled();
        },

        // Enable transitions when page is fully loaded
        initAfterLoad: function () {
            _transitionsEnabled();
        },

        // Initialize all sidebars
        initSidebars: function () {

            // On desktop
            _sidebarMainResize();
            _sidebarMainToggle();
            _sidebarSecondaryToggle();
            _sidebarRightMainToggle();
            _sidebarRightMainHide();
            _sidebarRightToggle();
            _sidebarRightSecondaryToggle();
            _sidebarComponentToggle();

            // On mobile
            _sidebarMobileFullscreen();
            _sidebarMobileMainToggle();
            _sidebarMobileSecondaryToggle();
            _sidebarMobileRightToggle();
            _sidebarMobileComponentToggle();
        },

        // Initialize all navigations
        initNavigations: function () {
            _navigationSidebar();
            _navigationNavbar();
        },

        // Initialize all components
        initComponents: function () {
            _componentTooltip();
            _componentPopover();
        },

        // Initialize all card actions
        initCardActions: function () {
            _cardActionReload();
            _cardActionCollapse();
            _cardActionRemove();
            _cardActionFullscreen();
        },

        // Dropdown submenu
        initDropdownSubmenu: function () {
            _dropdownSubmenu();
        },

        initHeaderElementsToggle: function () {
            _headerElements();
        },

        // Initialize core
        initCore: function () {
            App.initSidebars();
            App.initNavigations();
            App.initComponents();
            App.initCardActions();
            App.initDropdownSubmenu();
            App.initHeaderElementsToggle();
            console.log('core');
        }
    };
}();


// Initialize module
// ------------------------------

// When content is loaded
/*document.addEventListener('DOMContentLoaded', function () {
    App.initBeforeLoad();
    App.initCore();
});
*/
// When page is fully loaded
window.addEventListener('load', function () {
    App.initAfterLoad();
});
