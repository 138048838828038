import {Unite} from './unite';
import {MoyenneUnite} from './MoyenneUnite';
import {UniteSessionDiscipline} from './UniteSessionDiscipline';

export class UniteSession {
    id: string;
    ponderation: number;
    unite: Unite;
    unitesSessionsDisciplines: UniteSessionDiscipline[];
    moyennesUnites: MoyenneUnite[];

}
