import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSessionComponent } from './list-session/list-session.component';
import { AddSessionComponent } from './add-session/add-session.component';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {SessionRoutingModule} from './session-routing.module';

@NgModule({
  declarations: [ListSessionComponent, AddSessionComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    SessionRoutingModule
  ]
})
export class SessionModule { }
