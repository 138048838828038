import {Contrainte} from './Contrainte';
import {ConfigPlanning} from './ConfigPlanning';

export class SessionPlanning {
    id: string;
    annee: string;
    semestre: number;
    contraintes:Contrainte[];
    configs_plannings:ConfigPlanning[];

}
